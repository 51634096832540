import { getSPA, setSPA as setSPLA } from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import React, { useState } from "react";

type Props = {
    kcPoolName: string;
    name: string;
};

const SetSPLA = (props: Props) => {
    const [visible, setVisible] = useState(false);
    const [sqlUser, setSqlUser] = useState(0);
    const [sqlCore, setSqlCore] = useState(0);
    const [rdpUser, setRdpUser] = useState(0);
    const [vpnUser, setVpnUser] = useState(0);

    return (
        <>
            <Button
                className="w-full"
                label={props.name}
                onClick={() => setVisible(true)}
            />
            <Dialog
                style={{ width: "450px" }}
                header="SPLA setzen:"
                visible={visible}
                onHide={() => setVisible(false)}
                onShow={async () => {
                    let spas = await getSPA(props.name);
                    if (spas) {
                        setSqlUser(spas.nSQLUser);
                        setSqlCore(spas.nSQLCores);
                        setRdpUser(spas.nRDPUser);
                        setVpnUser(spas.nVPNUser);
                        console.log(spas);
                    }
                }}
            >
                <div className="grid">
                    <div className="col">
                        <label htmlFor="RDP User:" style={{ display: "block" }}>
                            RDP User:
                        </label>
                        <InputNumber
                            inputId="vertical"
                            value={rdpUser}
                            onValueChange={(e) => setRdpUser(e.value)}
                            mode="decimal"
                            showButtons
                            buttonLayout="vertical"
                            style={{ width: "4rem" }}
                            decrementButtonClassName="p-button-secondary"
                            incrementButtonClassName="p-button-secondary"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                        />
                    </div>
                    <div className="col">
                        <label
                            htmlFor="SQL Cores:"
                            style={{ display: "block" }}
                        >
                            SQL Cores:
                        </label>
                        <InputNumber
                            inputId="vertical"
                            value={sqlCore}
                            onValueChange={(e) => setSqlCore(e.value)}
                            mode="decimal"
                            showButtons
                            buttonLayout="vertical"
                            style={{ width: "4rem" }}
                            decrementButtonClassName="p-button-secondary"
                            incrementButtonClassName="p-button-secondary"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="SQL User:" style={{ display: "block" }}>
                            SQL User:
                        </label>
                        <InputNumber
                            inputId="vertical"
                            value={sqlUser}
                            onValueChange={(e) => setSqlUser(e.value)}
                            mode="decimal"
                            showButtons
                            buttonLayout="vertical"
                            style={{ width: "4rem" }}
                            decrementButtonClassName="p-button-secondary"
                            incrementButtonClassName="p-button-secondary"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="SQL User:" style={{ display: "block" }}>
                            VPN User:
                        </label>
                        <InputNumber
                            inputId="vertical"
                            value={vpnUser}
                            onValueChange={(e) => setVpnUser(e.value)}
                            mode="decimal"
                            showButtons
                            buttonLayout="vertical"
                            style={{ width: "4rem" }}
                            decrementButtonClassName="p-button-secondary"
                            incrementButtonClassName="p-button-secondary"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                        />
                    </div>
                </div>
                <Button
                    label="Speichern"
                    onClick={() => {
                        setVisible(false);
                        setSPLA(
                            props.kcPoolName,
                            sqlUser,
                            sqlCore,
                            rdpUser,
                            vpnUser
                        );
                    }}
                />
            </Dialog>
        </>
    );
};

export default SetSPLA;
