import {
    kampCreateSnapshotAdmin,
    kampGetSnapshotAdmin,
} from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import React, { useEffect, useState } from "react";

type Props = {
    kVim: number;
};

const SetKampSnapshot = (props: Props) => {
    const [visible, setVisible] = useState(false);
    const [core, setCore] = useState(0);
    const [ram, setRam] = useState(0);
    const [npStorage, setNpStorage] = useState(0);
    const [hpStorage, setHpStorage] = useState(0);
    const [user, setUser] = useState(0);
    const [sqlCore, setSqlCore] = useState(0);
    const [rdpUser, setRdpUser] = useState(0);

    useEffect(() => {
        console.log(props.kVim);
        console.log("test");
        kampGetSnapshotAdminHandler();
    }, []);

    const kampGetSnapshotAdminHandler = async () => {
        try {
            if (props.kVim) {
                const kampvim = await kampGetSnapshotAdmin(props.kVim);
                setCore(kampvim.nCores);
                setRam(kampvim.nRamInMb / 1024);
                setNpStorage(kampvim.nSpeicherNormalPerformance);
                setHpStorage(kampvim.nSpeicherHighPerformance);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Button label="KAMP" onClick={() => setVisible(true)} />
            <Dialog
                header="Kamp Ressourcen setzen:"
                visible={visible}
                onHide={() => setVisible(false)}
            >
                <div className="grid">
                    <div className="col">
                        <h5>Kerne:</h5>
                        <InputText
                            value={core}
                            onChange={(e: any) => setCore(e.target.value)}
                        />
                        <Slider
                            value={core}
                            onChange={(e: any) => setCore(e.value)}
                        />

                        <h5>RAM:</h5>
                        <InputText
                            value={ram}
                            onChange={(e: any) => setRam(e.target.value)}
                        />
                        <Slider
                            value={ram}
                            onChange={(e: any) => setRam(e.value)}
                        />
                    </div>
                    <div className="col">
                        <h5>NP Storage:</h5>
                        <InputText
                            value={npStorage}
                            onChange={(e: any) => setNpStorage(e.target.value)}
                        />
                        <Slider
                            value={npStorage}
                            onChange={(e: any) => setNpStorage(e.value)}
                        />
                        <h5>HP Storage:</h5>
                        <InputText
                            value={hpStorage}
                            onChange={(e: any) => setHpStorage(e.target.value)}
                        />
                        <Slider
                            value={hpStorage}
                            onChange={(e: any) => setHpStorage(e.value)}
                        />
                    </div>
                </div>
                <Button
                    label="Speichern"
                    onClick={() => {
                        setVisible(false);
                        kampCreateSnapshotAdmin(
                            props.kVim,
                            core,
                            ram,
                            npStorage,
                            hpStorage
                        );
                    }}
                />
            </Dialog>
        </>
    );
};

export default SetKampSnapshot;
