import React, { useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import { Button } from "primereact/button";

type Props = {
    btnLabel: string;
    btnIcon: string;
    btnClassNames: string;
    confirmMessage: string;
    confirmHeader: string;
    confirmAccept: any;
};

const ConfirmButton = ({
    btnLabel,
    btnIcon,
    btnClassNames,
    confirmMessage,
    confirmHeader,
    confirmAccept,
}: Props) => {
    const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
    return (
        <>
            <ConfirmDialog
                visible={confirmVisible}
                onHide={() => setConfirmVisible(false)}
                message={confirmMessage}
                header={confirmHeader}
                acceptIcon="pi pi-check"
                acceptLabel="Ja"
                rejectIcon="pi pi-times"
                rejectLabel="Nein"
                accept={() => confirmAccept()}
                reject={() => setConfirmVisible(false)}
            />

            <Button
                onClick={() => setConfirmVisible(true)}
                icon={btnIcon}
                label={btnLabel}
                className={btnClassNames}
            />
        </>
    );
};

export default ConfirmButton;
