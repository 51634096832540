import { adminGetDomains } from "logik/nodeApiCalls";
import React, { useEffect, useState } from "react";

type Props = {};

const DomainCounter = (props: Props) => {
    const [domains, setDomains] = useState<number>(0);

    useEffect(() => {
        getDomains();
    }, []);

    const getDomains = async () => {
        let _domains = await adminGetDomains();
        setDomains(_domains.nAnzahl ? _domains.nAnzahl : 0);
    };

    return (
        <div>
            <span className="block text-500 font-medium mb-3">
                Anzahl der Domains
            </span>
            <div className="text-900 font-medium text-xl">{domains}</div>
        </div>
    );
};

export default DomainCounter;
