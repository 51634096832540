import { DateTime } from "luxon";

type Props = {
    isoDate: string;
    format?: string;
};

const IsoDateParser = ({ isoDate, format }: Props) => {
    return (
        <>
            {DateTime.fromISO(isoDate, { zone: "utc" })
                .toFormat(format ? format : "dd/MM/yyyy")
                .toString()}
        </>
    );
};

export default IsoDateParser;
