import { resizeDisk } from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Message } from "primereact/message";
import { Slider } from "primereact/slider";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

type Props = {
    id: number;
    diskID: string;
    diskSizeNow: number;
    visible: boolean;
    onHide: () => void;
};

const ServerResizeDiskDialog = ({
    id,
    diskID,
    diskSizeNow,
    visible,
    onHide,
}: Props) => {
    const [additionalDiskSize, setAdditionalDiskSize] = useState<number>(0);

    const queryClient = useQueryClient();
    const changeDiskSize = useMutation(
        (values: { id: number; diskID: string; size: number }) =>
            resizeDisk(values.id, values.diskID, values.size),
        {
            onSuccess: async () => {
                queryClient.invalidateQueries(["server", id]);
            },
        }
    );

    const dialogFooter = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        onHide();
                    }}
                    label="Abbrechen"
                    icon="pi pi-times"
                    className="p-button-text"
                />
                <Button
                    disabled={additionalDiskSize == 0}
                    label="Übernehmen"
                    onClick={() => {
                        changeDiskSize.mutate({
                            id: id,
                            diskID: diskID,
                            size: additionalDiskSize,
                        });
                        onHide();
                    }}
                    icon="pi pi-check"
                    autoFocus
                />
            </div>
        );
    };

    return (
        <Dialog
            header="Festplattenspeicher vergrößern"
            visible={visible}
            onHide={onHide}
            footer={dialogFooter}
            style={{ width: "450px" }}
        >
            <Message
                className="mb-3"
                severity="info"
                text={`Der aktuelle Speicher kann nur vergrößert werden. (Von: ${diskSizeNow} GB - Auf: ${
                    diskSizeNow + additionalDiskSize
                } GB)`}
            />
            <InputNumber
                className="w-full"
                value={additionalDiskSize}
                onChange={(e) => setAdditionalDiskSize(e.value * 1024)}
                min={10}
                max={500}
                step={10}
                suffix=" GB"
            />
            <Slider
                value={additionalDiskSize}
                onChange={(e) =>
                    setAdditionalDiskSize(parseInt(e.value.toString()))
                }
                step={10}
                min={10}
                max={500}
            />
        </Dialog>
    );
};

export default ServerResizeDiskDialog;
