import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import CertificateCounter from "./CertificateCounter";
import CertificateVisual from "./CertificateVisual";
import YearlyCertificate from "./YearlyCertificate";
import { Button } from "primereact/button";
import DeleteButtonTar from "./DeleteButtonTar";
import {
    adminShowInnocigs,
    deleteInnocigs,
    withDrawInnocigs,
} from "logik/nodeApiCalls";
import { DateTime } from "luxon";
import ConfirmButton from "../../shared/ConfirmButton";

export interface InnocigsInterface {
    kcPool: string;
    dErstellt: string;
    fPreis: number;
    pool: Pool;
    bGekuendigt: number;
    dAboBeendetAm: string;
}

export interface Pool {
    kcPoolName: string;
    fkUser: number;
    qnexxtUser: QnexxtUser;
}

export interface QnexxtUser {
    cKundennummer: null | string;
    cFirma: null | string;
}

interface Props {}

const ShowInnocigs = (props: Props) => {
    const [innocigs, setInnocigs] = useState<InnocigsInterface[]>([]);
    useEffect(() => {
        showInnocigsList();
    }, []);

    const showInnocigsList = async () => {
        let innocigs = await adminShowInnocigs();
        console.log(innocigs);
        setInnocigs(innocigs);
    };

    const calculateInnoCigs = () => {
        let price = 0;
        innocigs.forEach((inocig) => {
            if (inocig.fPreis) price += inocig.fPreis;
        });

        return <>{price.toFixed(2)}</>;
    };

    const deleteInnoCigsHandler = async (kcPoolName: string) => {
        await deleteInnocigs(kcPoolName);
        console.log("set");
        await showInnocigsList();
    };

    const withDrawInnoCigsHandler = async (kcPoolName: string) => {
        await withDrawInnocigs(kcPoolName);
        await showInnocigsList();
    };

    return (
        <div>
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">
                                    Gebuchte Innocigs Tarife
                                </span>
                                <div className="text-900 font-medium text-xl">
                                    {innocigs.length}
                                </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">
                                    Montalich
                                </span>
                                <div className="text-900 font-medium text-xl">
                                    {calculateInnoCigs()} €
                                </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                {/* <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalfilterValue1}
              onChange={this.onGlobalfilterChange1}
              placeholder="Keyword Search"
            />
          </span> */}
                <div>
                    <DataTable
                        stripedRows
                        value={innocigs}
                        responsiveLayout="scroll"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        sortField="Pool"
                        sortOrder={1}
                    >
                        <Column
                            field="kcPool"
                            header="Pool"
                            sortable
                            filter
                        ></Column>
                        <Column
                            field="dErstellt"
                            header="Erstellt am"
                            sortable
                            filter
                            body={(rowdata: InnocigsInterface) => (
                                <Tag
                                    severity="info"
                                    value={DateTime.fromISO(
                                        rowdata.dErstellt
                                    ).toFormat("dd.MM.yyyy")}
                                ></Tag>
                            )}
                        ></Column>
                        <Column
                            field="fPreis"
                            header="Preis pro Monat"
                            sortable
                            filter
                            body={(rowdata: InnocigsInterface) => (
                                <Tag
                                    value={
                                        rowdata.fPreis
                                            ? rowdata.fPreis + " €"
                                            : ""
                                    }
                                ></Tag>
                            )}
                        ></Column>
                        <Column
                            field="qnexxtUser.cKundennummer"
                            header="KDN"
                            sortable
                            filter
                        />
                        <Column
                            header="Gekündigt?"
                            body={(rowdata: InnocigsInterface) => (
                                <>
                                    {rowdata.bGekuendigt ? (
                                        <Tag
                                            severity="warning"
                                            value={
                                                "Gekündigt am: " +
                                                DateTime.fromISO(
                                                    rowdata.dAboBeendetAm
                                                ).toFormat("dd.MM.yyyy")
                                            }
                                        ></Tag>
                                    ) : (
                                        <Tag severity={"info"} value="aktiv" />
                                    )}
                                </>
                            )}
                        ></Column>
                        <Column
                            body={(rowdata: InnocigsInterface) => {
                                console.log(rowdata.kcPool);
                                if (rowdata.dAboBeendetAm !== null) {
                                    return (
                                        <ConfirmButton
                                            btnLabel="Wiederrufen"
                                            btnIcon="pi pi-replay"
                                            btnClassNames="w-full"
                                            confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen kündigen?"
                                            confirmHeader="Bestätigung"
                                            confirmAccept={() =>
                                                withDrawInnoCigsHandler(
                                                    rowdata.kcPool
                                                )
                                            }
                                        />
                                    );
                                }
                                return (
                                    <ConfirmButton
                                        btnLabel="Kündigen"
                                        btnIcon="pi pi-times"
                                        btnClassNames="p-button-danger w-full"
                                        confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                        confirmHeader="Bestätigung"
                                        confirmAccept={() =>
                                            deleteInnoCigsHandler(
                                                rowdata.kcPool
                                            )
                                        }
                                    />
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default ShowInnocigs;
