export function utilitiesParseProxmoxValues(str, key) {
    var result = [];
    result["key"] = key;
    var split = str.split(",");
    split.forEach((s) => {
        if (!s.includes("=")) {
            result["first"] = s;
        } else {
            var splitsplit = s.split("=");
            result[splitsplit[0]] = splitsplit[1];
        }
    });
    return result;
}

export const parseMinutePriceToMonthPrice = (value) => {
    return (value * 60 * 24 * 30).toFixed(2);
};
