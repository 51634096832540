import { adminGetYearly } from "logik/nodeApiCalls";
import React, { useEffect, useState } from "react";
import { GiConsoleController } from "react-icons/gi";

const YearlyCounter = () => {
    const [sales, setSales] = useState<number>(0);

    useEffect(() => {
        getDomainSales();
    }, []);

    const getDomainSales = async () => {
        let _sales = await adminGetYearly();
        setSales(_sales.fValue ? _sales.fValue : 0);
    };

    return (
        <div>
            <span className="block text-500 font-medium mb-3">
                Jährlicher Domain Umsatz
            </span>
            <div className="text-900 font-medium text-xl">
                {Number(sales).toFixed(2)} €
            </div>
        </div>
    );
};

export default YearlyCounter;
