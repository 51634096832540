import { proxChangeVmSpec } from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Message } from "primereact/message";
import { Slider } from "primereact/slider";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

type Props = {
    id: number;
    ram: number;
    visible: boolean;
    onHide: () => void;
};

const ServerChangeRamDialog = ({ id, ram, visible, onHide }: Props) => {
    const [newRam, setNewRam] = useState<number>(ram);
    const [hasToReboot, setHasToReboot] = useState<boolean>(false);
    const [savetyCheckboxChecked, setSavetyCheckboxChecked] =
        useState<boolean>(false);

    const queryClient = useQueryClient();
    const changeSpec = useMutation(
        (values: { type: string; value: number; reboot: boolean }) =>
            proxChangeVmSpec(id, values.type, values.value),
        {
            onSuccess: async () => {
                queryClient.invalidateQueries(["server", id]);
            },
        }
    );

    const changeRamHandler = (value: number) => {
        const _hasToRebott = value < ram;
        setHasToReboot(_hasToRebott);
        setNewRam(value);
    };

    const dialogFooter = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        onHide();
                    }}
                    label="Abbrechen"
                    icon="pi pi-times"
                    className="p-button-text"
                />
                <Button
                    disabled={
                        (hasToReboot && !savetyCheckboxChecked) ||
                        ram === newRam
                    }
                    label="Übernehmen"
                    onClick={() => {
                        changeSpec.mutate({
                            type: "memory",
                            value: newRam,
                            reboot: hasToReboot,
                        });
                        onHide();
                    }}
                    icon="pi pi-check"
                    autoFocus
                />
            </div>
        );
    };

    return (
        <Dialog
            header="Bitte Wählen Sie Ihr RAM aus"
            visible={visible}
            onHide={onHide}
            footer={dialogFooter}
            style={{ width: "450px" }}
        >
            {hasToReboot && (
                <Message
                    className="mb-3"
                    severity="warn"
                    text="Sie sind dabei den verfügbaren RAM zu verkleinern, dazu muss der Server neu gestartet werden."
                />
            )}
            <InputNumber
                className="w-full"
                value={newRam / 1024}
                onChange={(e) => changeRamHandler(e.value * 1024)}
                min={1}
                max={128}
                suffix=" GB"
            />
            <Slider
                value={newRam}
                onChange={(e) => changeRamHandler(parseInt(e.value.toString()))}
                step={1024}
                min={1024}
                max={131072}
            />
            {hasToReboot && (
                <div className="flex align-items-center mt-3">
                    <Checkbox
                        inputId="savetyChecked"
                        checked={savetyCheckboxChecked}
                        onChange={(e) =>
                            setSavetyCheckboxChecked(!savetyCheckboxChecked)
                        }
                    />
                    <label
                        htmlFor="savetyChecked"
                        className="p-checkbox-label ml-2"
                    >
                        Ich bin mir darüber bewust, dass durch einen Neustart
                        nichtgespeicherte Daten{" "}
                        <b className="text-danger">verloren</b> gehen können.
                    </label>
                </div>
            )}
        </Dialog>
    );
};

export default ServerChangeRamDialog;
