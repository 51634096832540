import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import FileRestore from "../fileRestore/FileRestore";
import { confirmPopup } from "primereact/confirmpopup";
import { proxGetBackups, proxRestoreBackup } from "logik/nodeApiCalls";
import Loading from "components/shared/Loading";

type Props = {
    vmid: string;
    pool: string;
    name: string;
};

const ServerBackups = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [backups, setBackups] = useState([]);
    const [fileRestoreTime, setFileRestoreTime] = useState(null);

    const getBackups = async () => {
        var results = await proxGetBackups(props.vmid);
        console.log(results);

        setBackups(results);
        setLoading(false);
        console.log(results);
    };

    const confirm = (event: any, rowData: any) => {
        confirmPopup({
            target: event.currentTarget,
            message:
                "Sind Sie sich Sicher, die alte VM-Wird überspielt und ist dann nicht mehr verfügbar. Sie können vorher auch ein Backup machen",
            icon: "pi pi-exclamation-triangle",
            accept: () => restoreBackups(rowData.ctime, false),
            reject: () => null,
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            acceptIcon: "pi pi-check",
            rejectIcon: "pi pi-times",
            dismissable: true,
            acceptClassName: "p-button-danger",
        });
    };

    useEffect(() => {
        getBackups();
    }, []);

    const restoreBackups = async (cTime: string, toNewVm: any) => {
        var results = await proxRestoreBackup(
            props.vmid,
            cTime,
            props.name,
            toNewVm
        );
        console.log(results);
    };

    return (
        <ScrollPanel style={{ width: "100%", height: "736px" }}>
            {loading && (
                <div className="loadingContainer">
                    <Loading size="20vw" />
                </div>
            )}
            {loading === false && (
                <DataTable stripedRows value={backups}>
                    <Column
                        field="ctime"
                        header="Backup Vom"
                        body={(rowData) => {
                            var start = DateTime.fromSeconds(rowData.ctime);

                            return <>{start.toLocaleString()}</>;
                        }}
                    />
                    <Column
                        header="Backup Wiederherstellen im aktuellen Server"
                        className="p-text-center"
                        body={(rowData) => {
                            return (
                                <>
                                    <Button
                                        icon="pi pi-refresh"
                                        className="p-button-danger"
                                        onClick={(event) =>
                                            confirm(event, rowData)
                                        }
                                    ></Button>
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        header="File Restore"
                        className="p-text-center"
                        body={(rowData) => {
                            return (
                                <>
                                    <Button
                                        icon="pi pi-file"
                                        onClick={() =>
                                            setFileRestoreTime(rowData.ctime)
                                        }
                                    />
                                </>
                            );
                        }}
                    ></Column>
                    <Column
                        header="Wiederherstellen in neuen Server"
                        className="p-text-center"
                        body={(rowData) => {
                            return (
                                <>
                                    <Button
                                        icon="pi pi-refresh"
                                        onClick={() =>
                                            restoreBackups(rowData.ctime, true)
                                        }
                                    />
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>
            )}

            <FileRestore
                vmid={props.vmid}
                time={fileRestoreTime}
                onHide={() => setFileRestoreTime(null)}
            />
        </ScrollPanel>
    );
};

export default ServerBackups;
