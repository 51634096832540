import { useSocket } from "./useSocket";

export const useRefreshVm = (vmid: string, cb: () => void) => {
    useSocket("task", (data: any) => {
        console.log("push " + data);
        if (data === vmid) {
            cb();
        }
    });
};
