import React, { Component } from "react";
import withContext from "./withContext";
import { BlockUI } from "primereact/blockui";
import { DateTime } from "luxon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Login from "components/login/Login";
interface Props {
    minutesOld: number;
    context: any;
}
interface State {
    showDialog: boolean;
    smsSent: boolean;
    timeoutId: any;
    enrollmentDate: DateTime;
}

class Protection2FA extends Component<Props, State> {
    state = {
        showDialog: false,
        smsSent: false,
        timeoutId: 0,
        enrollmentDate: DateTime.local(),
    };
    constructor(props: Props) {
        super(props);

        this.makeTimeout = this.makeTimeout.bind(this);
    }

    componentDidMount() {
        // Soll der initial State geblockt werden?
        const enrollmentTime =
            this.props.context.user.multiFactor.user.metadata.lastSignInTime;
        var enrollmentDate = DateTime.fromRFC2822(enrollmentTime);
        let diff = DateTime.local()
            .diff(enrollmentDate, "minutes")
            .toObject().minutes;
        this.setState({
            showDialog: diff ? diff > this.props.minutesOld : false,
            enrollmentDate: enrollmentDate,
        });
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId);
    }

    render() {
        const enrollmentTime =
            this.props.context.user.multiFactor.user.metadata.lastSignInTime;
        var enrollmentDate = DateTime.fromRFC2822(enrollmentTime);
        if (
            this.state.enrollmentDate.toMillis() !== enrollmentDate.toMillis()
        ) {
            this.setState({
                enrollmentDate: enrollmentDate,
            });
            this.makeTimeout();
        }

        let diff = DateTime.now()
            .diff(enrollmentDate, "minutes")
            .toObject().minutes;

        return (
            <>
                <Dialog
                    onHide={() => this.setState({ showDialog: false })}
                    visible={this.state.showDialog}
                    header="Bitte noch einemal authentifizieren"
                >
                    <Login
                        redirect={false}
                        onLogin={async () => {
                            console.log("jo");
                            await this.props.context.refreshUser();
                            this.setState({ showDialog: false });
                            this.makeTimeout();
                        }}
                    />
                </Dialog>
                <BlockUI
                    blocked={diff ? diff > this.props.minutesOld : false}
                    template={() => (
                        <Button
                            label="Authentifizieren"
                            onClick={() => this.setState({ showDialog: true })}
                        />
                    )}
                >
                    {this.props.children}
                </BlockUI>
            </>
        );
    }

    makeTimeout(): void {
        clearTimeout(this.state.timeoutId);

        const enrollmentTime =
            this.props.context.user.multiFactor.user.metadata.lastSignInTime;
        var enrollmentDate = DateTime.fromRFC2822(enrollmentTime);

        // Timeout um  nach ablauf der Zeit es wieder zu blocken
        let msToBlock = enrollmentDate
            .plus({ minutes: this.props.minutesOld })
            .diff(DateTime.now(), "milliseconds").milliseconds;
        let timeoutId = setTimeout(() => {
            this.setState({
                showDialog: true,
            });
            this.forceUpdate();
        }, msToBlock);

        this.setState({ timeoutId: timeoutId });
    }
}
export default withContext(Protection2FA);
