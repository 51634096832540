import React, { Suspense, useEffect, useState } from "react";
import Layout from "components/layout/Layout";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory,
} from "react-router-dom";
import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { ProtectedRoute } from "./components/shared/ProtectedRoute";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import { rightsGetUserSettings } from "logik/nodeApiCalls";
import { ProgressSpinner } from "primereact/progressspinner";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LoginContainer from "components/login/LoginContainer";
import RegisterDomain from "components/domain/RegisterDomain";
import ActivateMFA from "components/login/ActivateMFA";
import PaymentOverview from "components/payment/PaymentOverview";
import AddUser from "components/administration/manuelleEinpflegung/AddUser";
import AddDomain from "components/administration/manuelleEinpflegung/AddDomain";
import ShowAbo from "components/administration/manuelleEinpflegung/ShowAbo";
import AddTariff from "components/administration/manuelleEinpflegung/AddTariff";
import ShowCertificates from "components/administration/manuelleEinpflegung/ShowCertificates";
import AddCertificate from "components/administration/manuelleEinpflegung/AddCertificate";
import ShowUserTariff from "components/administration/manuelleEinpflegung/ShowUserTariff";
import FullUserData from "components/administration/manuelleEinpflegung/FullUserData";
import ShowInnocigs from "components/administration/manuelleEinpflegung/ShowInnocigs";
import { nodeAxios } from "logik/nodeApiCalls";
import PoolManagement from "components/administration/management/PoolManagement";
import { Menu } from "components/layout/Layout";
import ServerDetailPage from "components/server/server/ServerDetailPage";

const BuyDomainFromShop = React.lazy(
    () => import("components/domain/BuyDomainFromShop")
);

const KampListe = React.lazy(
    () => import("components/administration/kamp/KampListe")
);
const ServerDetailContainer = React.lazy(
    () => import("components/server/serverdashboard/ServerDetailContainer")
);
const AccountContainer = React.lazy(
    () => import("components/account/AccountContainer")
);

const TarifManagement = React.lazy(
    () => import("components/server/TarifMangagement/TarifManagement")
);

const FourZeroFourPage = React.lazy(
    () => import("components/other/FourZeroFourPage")
);
const CostsOveriew = React.lazy(
    () => import("components/server/CostsOverview/CostsOveriew")
);
const PoolContainer = React.lazy(
    () => import("components/server/server/PoolContainer")
);
const PoolsRightMangagement = React.lazy(
    () => import("components/rightManagement/PoolsRightMangagement")
);
const ShoppingListContainer = React.lazy(
    () => import("components/administration/shoppinglist/ShoppingListContainer")
);
const Registrate = React.lazy(() => import("components/login/Registrate"));
const RenewPassword = React.lazy(
    () => import("components/login/RenewPassword")
);
const RequestNewPw = React.lazy(() => import("components/login/RequestNewPW"));
const CreateNewVM = React.lazy(
    () => import("components/server/createnewserver/CreateNewVmContainer")
);

type UserSettings = {
    isReseller: string;
    rights: any[];
    user: any;
};

export const UserContext = React.createContext({
    isReseller: "0",
    rights: new Array(),
    username: "",
    addNotification: async () => {},
    user: undefined,
    refreshUser: async () => {},
});

const App = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isAuthenticated, setisAuthenticated] = useState<boolean>(false);
    const [userSettings, setuserSettings] = useState<UserSettings>({
        isReseller: "0",
        rights: [],
        user: undefined,
    });

    /* useEffect(() => {
        const socket = socketIO("http://localhost:4000");

        socket.on("FromAPI", (data) => {
            console.log(data);
        });

        return () => {
            socket.disconnect();
        };
    }, []); */

    const [userName, setuserName] = useState<string>("");
    const [menu, setMenu] = useState<Menu>({
        std: {
            name: "SYNTRO.CLOUD",
            menuItems: [
                {
                    title: "Dashboard",
                    icon: "pi pi-align-justify",
                    to: "/dashboard",
                },
            ],
        },
        admin: { name: "ADMINISTARTION", menuItems: [] },
        views: { name: "ÜBERSICHTEN", menuItems: [] },
        migration: { name: "MIGRATION", menuItems: [] },
    });
    const location = useLocation();
    const history = useHistory();

    PrimeReact.ripple = true;

    useEffect(() => {
        authenticate();
    }, []);

    useEffect(() => {
        buildMenu();
    }, [userSettings.rights]);

    const authenticate = async () => {
        try {
            firebase.auth().onAuthStateChanged(async (user) => {
                console.log("user auth changed", user);
                if (user) {
                    try {
                        console.log(user);
                        let token = await user.getIdToken();
                        console.log(token);
                        axios.defaults.headers = {
                            Auth: token,
                        };

                        nodeAxios.defaults.headers = {
                            Authorization: token,
                        };

                        console.log(nodeAxios.defaults.headers);
                    } catch (err) {
                        console.log(err);
                    }
                    console.log(nodeAxios.defaults.headers.Authorization);
                    var userSettings = await rightsGetUserSettings();
                    console.log("userSettings", userSettings);
                    setLoading(false);
                    setisAuthenticated(true);
                    setuserName(
                        user.displayName ? user.displayName : "Unbekannt"
                    );
                    setuserSettings({
                        ...userSettings,
                        addNotification: addNotification,
                        user: user,
                    });
                } else {
                    setLoading(false);
                    setisAuthenticated(false);
                    setuserSettings({
                        isReseller: "0",
                        rights: [],
                        user: null,
                    });
                    setuserName("");
                    axios.defaults.headers = {
                        Auth: "",
                    };
                    nodeAxios.defaults.headers = {
                        Authorization: "",
                    };
                }
            });
        } catch (err) {
            console.warn(err);
        }
    };

    const addNotification = async () => {};

    const refreshUser = async () => {
        var user = firebase.auth().currentUser;
        let newUserSettings = {
            userSettings: {
                ...userSettings,
                addNotification: addNotification,
                user: user,
            },
        };
    };

    const buildMenu = () => {
        let _menu = { ...menu };
        _menu.std.menuItems = [
            {
                title: "Dashboard",
                icon: "pi pi-align-justify",
                to: "/dashboard",
            },
        ];
        _menu.admin.menuItems = [];
        _menu.views.menuItems = [];
        _menu.migration.menuItems = [];
        const rights = userSettings.rights;
        rights.findIndex((right) => right.kRight == 4) &&
            _menu.std.menuItems.push({
                title: "Serverdashboard",
                icon: "pi pi-list",
                to: "/server",
            });

        rights.findIndex((right) => right.kRight == 3) &&
            _menu.std.menuItems.push({
                title: "Neuen Server erstellen",
                icon: "pi pi-plus-circle",
                to: "/newserver",
            });

        rights.findIndex((right) => right.kRight == 20) &&
            _menu.std.menuItems.push({
                title: "Domain Registrieren",
                icon: "pi pi-plus-circle",
                to: "/registerDomain",
            });

        rights.findIndex((right) => right.kRight == 12) &&
            _menu.std.menuItems.push({
                title: "Rechte-Management",
                icon: "pi pi-info-circle",
                to: "/rightmanagement",
            });

        rights.findIndex((right) => right.kRight == 22) &&
            _menu.std.menuItems.push({
                title: "Bezahlinformationen",
                icon: "pi pi-dollar",
                to: "/bezahlinformationen",
            });

        if (rights.findIndex((right) => right.kRight === 1000)) {
            // Adminmenu
            _menu.admin.menuItems.push({
                title: "Tarife",
                icon: "pi pi-user",
                to: "/adminTarife",
            });
            _menu.admin.menuItems.push({
                title: "Einkaufsliste",
                icon: "pi pi-shopping-cart",
                to: "/shoppinglist",
            });
            // Views
            _menu.views.menuItems.push({
                title: "User-All-in-One",
                icon: "pi pi-users",
                to: "/fullUserData",
            });
            _menu.views.menuItems.push({
                title: "Domains",
                icon: "pi pi-list",
                to: "/ShowAbo",
            });
            _menu.views.menuItems.push({
                title: "Zertifikate",
                icon: "pi pi-list",
                to: "/ShowCertificates",
            });
            _menu.views.menuItems.push({
                title: "User-Tarife",
                icon: "pi pi-list",
                to: "/ShowUserTariff",
            });
            _menu.views.menuItems.push({
                title: "Innocigs",
                icon: "pi pi-list",
                to: "/ShowInnocigs",
            });

            _menu.migration.menuItems.push({
                title: "User hinzufügen",
                icon: "pi pi-user-plus",
                to: "/adminAddUser",
            });

            _menu.migration.menuItems.push({
                title: "Tarif hinzufügen",
                icon: "pi pi-plus-circle",
                to: "/AddTariff",
            });

            _menu.migration.menuItems.push({
                title: "Domain hinzufügen",
                icon: "pi pi-plus-circle",
                to: "/adminAddDomain",
            });

            _menu.migration.menuItems.push({
                title: "Zertifikat hinzufügen",
                icon: "pi pi-plus-circle",
                to: "/addCertificate",
            });
        }
        setMenu(_menu);
    };

    return (
        <>
            {!loading && (
                <UserContext.Provider
                    value={{
                        ...userSettings,
                        username: userName,
                        refreshUser: refreshUser,
                        addNotification: addNotification,
                    }}
                >
                    <Switch>
                        <Route path="/login">
                            <LoginContainer redirect={true}>
                                <Login
                                    onLogin={() => {
                                        history.push("/");
                                    }}
                                    redirect={true}
                                />
                            </LoginContainer>
                        </Route>
                        <Route path="/activateMFA">
                            <LoginContainer redirect={true}>
                                <ActivateMFA />
                            </LoginContainer>
                        </Route>
                        <Route path="/buyDomain">
                            <Suspense fallback={<div>loading...</div>}>
                                <BuyDomainFromShop />
                            </Suspense>
                        </Route>
                        <Route path="/registration">
                            <Suspense fallback={<div>Loading...</div>}>
                                <LoginContainer redirect={true}>
                                    <Registrate />
                                </LoginContainer>
                            </Suspense>
                        </Route>
                        <Route path="/newpassword">
                            <Suspense fallback={<div>Loading...</div>}>
                                <LoginContainer redirect={true}>
                                    <RenewPassword />
                                </LoginContainer>
                            </Suspense>
                        </Route>
                        <Route path="/requestNewPw">
                            <Suspense fallback={<div>Loading...</div>}>
                                <LoginContainer redirect={true}>
                                    <RequestNewPw />
                                </LoginContainer>
                            </Suspense>
                        </Route>
                        <Layout menu={menu}>
                            <AnimatePresence>
                                <Suspense fallback={<ProgressSpinner />}>
                                    <Switch
                                        location={location}
                                        key={location.pathname}
                                    >
                                        <ProtectedRoute exact path="/">
                                            <Redirect to="/dashboard" />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/server/:id">
                                            <ServerDetailPage />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/server">
                                            <PoolContainer />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/dashboard">
                                            <Dashboard />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/newserver">
                                            <CreateNewVM />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/chronic">
                                            <CostsOveriew />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/registerDomain">
                                            <RegisterDomain />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/rightmanagement">
                                            <PoolsRightMangagement />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/bezahlinformationen">
                                            {/* <PaymentOverview /> */}
                                            <div>PaymentOverwie</div>
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/shoppinglist">
                                            <ShoppingListContainer />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/account">
                                            <AccountContainer />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/adminTarife">
                                            <TarifManagement />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/adminAddUser">
                                            <AddUser />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/adminAddDomain">
                                            <AddDomain />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/adminPoolManagement">
                                            <PoolManagement />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/AddTariff">
                                            <AddTariff />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/ShowAbo">
                                            <ShowAbo />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/ShowCertificates">
                                            <ShowCertificates />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/AddCertificate">
                                            <AddCertificate />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/ShowUserTariff">
                                            <ShowUserTariff />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/FullUserData">
                                            <FullUserData />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/ShowInnocigs">
                                            <ShowInnocigs />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="*">
                                            <FourZeroFourPage />
                                        </ProtectedRoute>
                                    </Switch>
                                </Suspense>
                            </AnimatePresence>
                        </Layout>
                    </Switch>
                </UserContext.Provider>
            )}
        </>
    );
};

export default App;
