import axios from "axios";
export const nodeAxios = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_DOMAIN_NODE,
});

// export const adminShowAbos = async () => {
//     let res = await axios.post("administration/manuelleEinpflegung/showAbos.php", {
//     });
//     return res.data
// };

export interface Vim {
    diskwrite: number;
    uptime: number;
    netout: number;
    template: number;
    cpu: number;
    maxdisk: number;
    disk: number;
    status: string;
    name: string;
    mem: number;
    netin: number;
    maxcpu: number;
    maxmem: number;
    diskread: number;
    type: string;
    node: string;
    vmid: number;
    id: string;
}

export interface Server {
    vminfo: Vminfo;
    vmstatus: Vmstatus;
}

export interface Vminfo {
    vmgenid: string;
    memory: number;
    machine: string;
    net0: string;
    name: string;
    cores: number;
    scsihw: string;
    numa: number;
    boot: string;
    scsi1: string;
    hotplug: string;
    ide2: string;
    cpu: string;
    agent: string;
    smbios1: string;
    scsi0: string;
    [key: string]: any;
    ostype: string;
    sockets: number;
    digest: string;
}

export interface Vmstatus {
    maxdisk: number;
    nics: Nics;
    netout: number;
    balloon: number;
    cpus: number;
    uptime: number;
    cpu: number;
    status: string;
    "running-qemu": string;
    diskwrite: number;
    "running-machine": string;
    ha: Ha;
    qmpstatus: string;
    mem: number;
    disk: number;
    name: string;
    freemem: number;
    "proxmox-support": ProxmoxSupport;
    maxmem: number;
    pid: number;
    diskread: number;
    vmid: number;
    agent: number;
    netin: number;
    ballooninfo: Ballooninfo;
    blockstat: Blockstat;
}

export interface Ballooninfo {
    mem_swapped_out: number;
    actual: number;
    free_mem: number;
    total_mem: number;
    last_update: number;
    major_page_faults: number;
    max_mem: number;
    mem_swapped_in: number;
    minor_page_faults: number;
}

export interface Blockstat {
    scsi0: Ide2;
    scsi1: Ide2;
    ide2: Ide2;
}

export interface Ide2 {
    account_invalid: boolean;
    account_failed: boolean;
    unmap_bytes: number;
    wr_total_time_ns: number;
    failed_wr_operations: number;
    failed_unmap_operations: number;
    failed_rd_operations: number;
    invalid_flush_operations: number;
    timed_stats: any[];
    failed_flush_operations: number;
    invalid_rd_operations: number;
    unmap_merged: number;
    rd_total_time_ns: number;
    wr_highest_offset: number;
    flush_total_time_ns: number;
    invalid_unmap_operations: number;
    rd_operations: number;
    flush_operations: number;
    rd_merged: number;
    rd_bytes: number;
    unmap_total_time_ns: number;
    invalid_wr_operations: number;
    wr_operations: number;
    wr_bytes: number;
    unmap_operations: number;
    wr_merged: number;
    idle_time_ns?: number;
}

export interface Ha {
    state: string;
    group: string;
    managed: number;
}

export interface Nics {
    tap128i0: Tap128I0;
}

export interface Tap128I0 {
    netin: number;
    netout: number;
}

export interface ProxmoxSupport {
    "pbs-dirty-bitmap-migration": boolean;
    "query-bitmap-info": boolean;
    "pbs-masterkey": boolean;
    "pbs-dirty-bitmap-savevm": boolean;
    "pbs-library-version": string;
    "pbs-dirty-bitmap": boolean;
}

export const proxGetServerList = async (pool: string): Promise<Vim[]> => {
    var serverlist: any[] = [];
    console.log(nodeAxios.defaults);
    await nodeAxios.get(`server/getServerList/` + pool).then((res) => {
        if (res.data.length > 0) {
            serverlist = Object.values(res.data);
        }
    });
    console.log(serverlist);
    return serverlist.sort((a, b) => (a.vmid > b.vmid ? 1 : -1));
};

export const proxGetVmInfo = async (vmid: any): Promise<Server> => {
    const res = await nodeAxios.get("server/getVmInfo/" + vmid);
    return res.data;
};

export const apiCancelAbo = async (
    kAbo: number,
    dErstellt: string,
    kcPoolName: string
) => {
    const res = await nodeAxios.post("admin/abos/delete", {
        kAbo,
        dErstellt,
        kcPoolName,
    });
    console.log(res);
    return res.data;
};

export const withDrawAbo = async (kAbo: number) => {
    const res = await nodeAxios.post("admin/abos/withdraw", {
        kAbo,
    });
    console.log(res);
    return res.data;
};

export const apiCancelCerts = async (kCertificate: number) => {
    const res = await nodeAxios.post("admin/certs/delete", {
        kCertificate,
    });
    return res.data;
};

export const withDrawCerts = async (kCertificate: number) => {
    const res = await nodeAxios.post("admin/certs/withdraw", {
        kCertificate,
    });
    return res.data;
};

export const apiCancelTarif = async (kVim: number) => {
    const res = await nodeAxios.post("admin/tarife/delete", {
        kVim,
    });
    return res.data;
};

export const apiwithDrawTarif = async (kVim: number) => {
    const res = await nodeAxios.post("admin/tarife/withdraw", {
        kVim,
    });
    return res.data;
};

export const getVimCount = async () => {
    const res = await nodeAxios.post("admin/tarife/countvims");
    return res.data;
};

export const adminShowTariff = async () => {
    const response = await nodeAxios.get("admin/tarife/tarife");
    return response.data;
};

export const adminShowInnocigs = async () => {
    const response = await nodeAxios.get("admin/Innocigs/innocigs");
    return response.data;
};

export const deleteInnocigs = async (kcPoolName: string) => {
    const response = await nodeAxios.post("admin/Innocigs/delete", {
        kcPoolName,
    });
    return response.data;
};

export const withDrawInnocigs = async (kcPoolName: string) => {
    const response = await nodeAxios.post("admin/Innocigs/withdraw", {
        kcPoolName,
    });
    return response.data;
};

export const updateVmNames = async () => {
    const response = await nodeAxios.get("admin/tarife/names");
    return response.data;
};

export const proxCreateSnapshotAdmin = async (vmid: string) => {
    const response = await nodeAxios.post("admin/snapshot/create/", {
        vmid: vmid,
    });
    return response.data.data;
};

export const kampGetSnapshotAdmin = async (kVim: number) => {
    const response = await nodeAxios.post("admin/snapshot/kamp/", {
        kVim,
    });
    return response.data;
};

export const kampCreateSnapshotAdmin = async (
    kVim: number,
    cores: number,
    ram: number,
    npStorage: number,
    hpStorage: number
) => {
    const response = await nodeAxios.post("admin/snapshot/kampCreate/", {
        kVim,
        cores,
        ram,
        npStorage,
        hpStorage,
    });
    return response.data.data;
};

export const setSPA = async (
    kcPoolName: string,
    cSQLuser: number,
    sqlCores: number,
    rdpUser: number,
    vpnUser: number
) => {
    const response = await nodeAxios.post("admin/abos/spa/", {
        kcPoolName,
        cSQLuser,
        sqlCores,
        rdpUser,
        vpnUser,
    });
    return response.data.data;
};

export const getSPA = async (name: string) => {
    const response = await nodeAxios.post("admin/abos/getSPA/", {
        name,
    });
    return response.data;
};
export const adminAddUser = async (
    userID: string,
    firmaName: string,
    email: string
) => {
    const response = await nodeAxios.post("admin/user/create", {
        uid: userID,
        username: firmaName,
        email: email,
    });
    return response;
};

export const proxGetTarifForVm = async (vmid: string) => {
    const response = await nodeAxios.post("tarif/tarifforvm", {
        vmid: vmid,
    });
    return response.data;
};

export const proxGetRrdData = async (vmid: any, timeframe: any) => {
    var response = await nodeAxios.post(`server/rrdData`, {
        vmid: vmid,
        timeframe: timeframe,
    });
    return response.data;
};

export const proxGetBackups = async (vmid: any) => {
    var response = await nodeAxios.post(`backups/getBackups`, {
        vmid: vmid,
    });

    return response.data;
};

export const proxRestoreBackup = async (
    vmid: any,
    cTime: any,
    name: any,
    toNewVm: any
) => {
    var response = await nodeAxios.post(`backups/restoreBackup`, {
        vmid: vmid,
        cTime: cTime,
        name: name,
        toNewVm: toNewVm ? "1" : "0",
    });
    return response.data.data;
};

export const rightsGetUserSettings = async () => {
    const response = await nodeAxios.get("settings");
    console.log("Settings endpoint");
    return response.data;
};

export const adminGetUser = async () => {
    var response = await nodeAxios.get(`admin/user/`);
    return response.data;
};

export const proxGetPools = async (): Promise<string[]> => {
    var response = await nodeAxios.get(`server/getPools`);
    var pools: any[] = [];
    console.log(response.data);
    Object.values(response.data).forEach((pool: any) => {
        pools.push(pool.kcPool);
    });
    return pools;
};

export const proxGetVerlauf = async (vmid: any) => {
    var response = await nodeAxios.post(`server/getVmVerlauf`, {
        vmid: vmid,
    });
    console.log(response.data);
    return response.data;
};

export const datalogsGetSnapshots = async (vmid: string) => {
    var response = await nodeAxios.post(`tarif/getSnapshots`, {
        vmid: vmid,
    });
    console.log(response.data);
    return response.data;
};

export const proxChangeVmName = async (vmid: any, name: string) => {
    const response = await nodeAxios.post("server/changeName", {
        vmid: vmid,
        name: name,
    });
    await updateVmNames();

    return response.data.data;
};

export const proxCloneServer = async (vmid: string, pool: string) => {
    var response = await nodeAxios.post(`server/clone`, {
        vmid: vmid,
        pool: pool,
    });

    return response;
};

export const proxBackUpServer = async (vmid: any) => {
    var response = await nodeAxios.post(`backups/startVmBackup`, {
        vmid: vmid,
    });
    return response;
};

export const proxChangeVmSpec = async (
    vmid: any,
    spec: string,
    value: number
) => {
    const response = await nodeAxios.post("server/changeSpecs", {
        vmid: vmid,
        spec: spec,
        value: value,
    });
    return response.data.data;
};

export const resizeDisk = async (vmid: number, disk: string, size: number) => {
    const response = await nodeAxios.post("server/resizeDisk", {
        vmid: vmid,
        disk: disk,
        size: size,
    });
    return response.data.data;
};

export const proxServerAction = async (
    vmid: any,
    action: "start" | "stop" | "reboot"
) => {
    const response = await nodeAxios.post("server/serverAction", {
        vmid: vmid,
        action: action,
    });
    return response.data.data;
};

export const adminShowAbos = async () => {
    var response = await nodeAxios.post("admin/abos/showAbos");
    return response.data;
};

export const adminShowCert = async () => {
    const response = await nodeAxios.post("admin/certs/showCerts");
    return response.data;
};

export const getAdditionalTarifs = async (categorie: string) => {
    const res = await nodeAxios.post("admin/tarife/getadditionaltarifs", {
        categorie,
    });
    return res.data;
};

export const getAdditionalTarifsByPool = async (user: string) => {
    const res = await nodeAxios.post(
        `admin/tarife/getadditionaltarifs?user=${user}`
    );
    console.log(res);
    return res.data;
};

export const addAdditionalTarifs = async (
    categorie: string,
    name: string,
    fPreis: number,
    nLaufzeitInMonaten: number,
    nHPSpeicherWebHosting: number
) => {
    const res = await nodeAxios.post("admin/tarife/addadditionaltarifs", {
        categorie,
        name,
        fPreis,
        nLaufzeitInMonaten,
        nHPSpeicherWebHosting,
    });
    return res.data;
};

export const getCertList = async () => {
    const response = await axios.post("admin/certs/getCerts");
    return response.data;
};

export const adminAddDomain = async (
    userID: string,
    firmaName: string,
    email: string,
    price: number,
    user: string,
    typed: string,
    tld: string
) => {
    const response = await nodeAxios.post("admin/abos/create", {
        uid: userID,
        username: firmaName,
        email: email,
        price: price,
        user: user,
        typed: typed,
        tld: tld,
    });
    return response;
};

export const adminAddTarif = async (
    domain: string,
    vmid: number,
    datum: string,
    preis: number,
    user: string,
    tariff: number,
    ftariff: number
) => {
    var response = await nodeAxios.post("admin/tarife/addTariff.php", {
        domain: domain,
        vmid: vmid,
        datum: datum,
        preis: preis,
        user: user,
        tariff: tariff,
        ftariff: ftariff,
    });
    return response.data;
};

export const adminAddCertificate = async (
    domain: string,
    id: string,
    price: number,
    datum: string,
    till: string,
    status: string,
    type: number,
    checked: boolean
) => {
    var response = await nodeAxios.post("admin/certs/addCert.php", {
        domain: domain,
        id: id,
        price: price,
        datum: datum,
        till: till,
        status: status,
        type: type,
        checked: checked,
    });
    return response.data;
};

export const showUserAbo = async (user: string) => {
    const res = await nodeAxios.post(`admin/abos/showUserAbo?user=${user}`);
    return res.data;
};

export const showUserTariff = async (user: string) => {
    const res = await nodeAxios.post(
        `admin/tarife/showUserTariff?user=${user}`
    );
    console.log(res);
    return res.data;
};

export const showUserAdditionalTariff = async (user: string) => {
    const res = await nodeAxios.post(
        `admin/tarife/showUserAdditionalTariff?user=${user}`
    );
    return res.data;
};

export const showUserCert = async (user: string) => {
    const res = await nodeAxios.post(`admin/certs/showUserCerts?user=${user}`);
    return res.data;
};

export const certList = async () => {
    const response = await nodeAxios.post("admin/certs/certList");
    return response.data;
};

export const certCount = async () => {
    const response = await nodeAxios.post("admin/certs/certCount");
    return response.data;
};

export const certCountCard = async () => {
    const response = await nodeAxios.post("admin/certs/certCountCard");
    return response.data;
};

export const certYearlyCard = async () => {
    const response = await nodeAxios.post("admin/certs/certYearlyCard");
    return response.data;
};

export const adminPoolList = async () => {
    var response = await nodeAxios.post("admin/abos/poolList.php", {});
    return response.data;
};

export const adminGetTLD = async () => {
    var response = await nodeAxios.post("admin/abos/tldCount.php", {});
    return response.data;
};

export const adminGetDomains = async () => {
    var response = await nodeAxios.get("/admin/domains/domaincount");
    return response.data;
};

export const adminGetYearly = async () => {
    var response = await nodeAxios.get("/admin/domains/domainsales");
    return response.data;
};

export const proxGetFilesForFileRestore = async (
    vmid: any,
    cTime: any,
    path: string
) => {
    var response = await nodeAxios.post("/backups/getFileRestoreFiles", {
        vmid: vmid,
        cTime: cTime,
        path: path,
    });
    return response.data;
};

export const proxDownloadFilesForFileRestore = async (
    vmid: any,
    cTime: any,
    path: any,
    leaf: any
) => {
    var response = await nodeAxios.post("backups/downloadFilesForFileRestore", {
        vmid: vmid,
        cTime: cTime,
        leaf: leaf,
        path: path,
    });
    if (typeof response.data === "string") {
        return null;
    }

    return response.data;
};

export const proxGetImages = async () => {
    var response = await nodeAxios.post("server/getImages");
    return response.data;
};

export const proxGetVlans = async (pool: any) => {
    var response = await nodeAxios.post(`management/getVlans`, {
        pool: pool,
    });
    console.log(response);
    var vlans: { vlan: any; fkcPoolName: any }[] = [];
    try {
        Object.values(response.data).forEach((vlan: any) => {
            vlans.push({ vlan: vlan.kVlan, fkcPoolName: vlan.fkcPoolName });
        });

        return vlans;
    } catch (error) {
        return [];
    }
};

export const proxGetTarife = async () => {
    const res = await nodeAxios.post("tarif/getTarife");
    return res.data;
};

export const proxGetCurrentFlexTarif = async () => {
    const response = await nodeAxios.post("tarif/getCurrentFlexTarif");
    return response.data;
};

export const proxCreateVM = async (state: any) => {
    await nodeAxios.post(`newvm/createNewVM`, {
        vmname: state.vmname,
        vmram: state.vmram,
        vmcores: state.vmcores % 2 === 0 ? state.vmcores / 2 : state.vmcores,
        nodename: state.nodename,
        vmsockets: state.vmcores % 2 === 0 ? 2 : 1,
        pool: state.pool.pool,
        laufwerke: state.laufwerke,
        vlan: state.vlan.vlan,
        imageid: state.ostype,
    });
};
