import axios from "axios";
import { adminAddUser } from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { Component } from "react";

type Props = {};

type State = {
    userID: string;
    firmaName: string;
    email: string;
};

export default class AddUser extends Component<Props, State> {
    state = {
        userID: "",
        firmaName: "",
        email: "",
    };
    toast: any;

    constructor(props: Props) {
        super(props);

        this.addUser = this.addUser.bind(this);
    }

    render() {
        return (
            <div>
                <h1>Pool User in Proxmox hinzufügen</h1>
                <Toast ref={(el) => (this.toast = el)} />
                <span className="p-float-label">
                    <InputText
                        id="inputtext"
                        value={this.state.userID}
                        onChange={(e) =>
                            this.setState({ userID: e.target.value })
                        }
                    />
                    <label htmlFor="inputtext">UserID</label>
                </span>
                <br></br>
                <span className="p-float-label">
                    <InputText
                        id="jooo"
                        value={this.state.firmaName}
                        keyfilter={/[\d\w_]+/}
                        onChange={(e) =>
                            this.setState({ firmaName: e.target.value })
                        }
                    />
                    <label htmlFor="jooo">
                        FirmaName ohne Leer und sonderzeichen
                    </label>
                </span>
                <br></br>

                <span className="p-float-label">
                    <InputText
                        id="jooos"
                        value={this.state.email}
                        onChange={(e) =>
                            this.setState({ email: e.target.value })
                        }
                    />
                    <label htmlFor="jooos">Email</label>
                </span>
                <Button
                    className="mt-3"
                    label="Mach halt!"
                    onClick={this.addUser}
                />
            </div>
        );
    }

    addUser = async () => {
        /* let res = await axios.post(
            "administration/manuelleEinpflegung/addUser.php",
            {
                uid: this.state.userID,
                username: this.state.firmaName,
                email: this.state.email,
            }
        ); */
        let res = await adminAddUser(
            this.state.userID,
            this.state.firmaName,
            this.state.email
        );

        if (res.data.messageType === "success") {
            this.toast.current.show({
                severity: "success",
                summary: "Success Message",
                detail: "Order submitted",
            });
        } else {
            this.toast.current.show({
                severity: "error",
                summary: res.data.message,
                detail: "Order not submitted",
            });
        }
        console.log(res.data);
    };
}
