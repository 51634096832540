import React, { Component } from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { proxGetVerlauf } from "logik/nodeApiCalls";
import { DateTime } from "luxon";

export default class ServerVerlauf extends Component {
    static propTypes = {
        vmid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            verlauf: [],
        };

        this.getVerlauf = this.getVerlauf.bind(this);
    }

    componentDidMount() {
        this.getVerlauf();
    }

    render() {
        return (
            <div>
                <DataTable
                    stripedRows
                    value={this.state.verlauf}
                    paginator
                    rows={15}
                    rowsPerPageOptions={[15, 30, 45, 60, 75, 90]}
                >
                    <Column
                        field="time"
                        header="Datum"
                        body={(rowData) => (
                            <>
                                {DateTime.fromSeconds(
                                    rowData.time
                                ).toLocaleString(DateTime.DATETIME_MED)}

                                {"endtime" in rowData && (
                                    <>
                                        <br />
                                        {" bis " +
                                            DateTime.fromSeconds(
                                                rowData.endtime
                                            ).toLocaleString(
                                                DateTime.DATETIME_MED
                                            )}
                                    </>
                                )}
                            </>
                        )}
                    ></Column>
                    <Column field="detail" header="Beschreibung"></Column>
                    <Column field="type" header="Typ"></Column>
                </DataTable>
            </div>
        );
    }

    async getVerlauf() {
        var verlauf = await proxGetVerlauf(this.props.vmid);
        this.setState({ verlauf: verlauf });
    }
}
