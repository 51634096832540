import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import UserAutocomplete from "./UserAutocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { throws } from "assert";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { adminAddCertificate, getCertList } from "logik/nodeApiCalls";

type Props = {};

type State = {
    domain: string;
    type: number;
    id: string;
    datum: any;
    price: number;
    status: string;
    till: any;
    label: string;
    tLabel: string;
    certs: CertList[];
    checked: boolean;
};

export interface CertList {
    cName: string;
    nID: number;
}

export default class AddDomain extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.saveCert = this.saveCert.bind(this);
        this.onLoadingClick1 = this.onLoadingClick1.bind(this);
        this.getCerts = this.getCerts.bind(this);

        this.state = {
            domain: "",
            id: "1",
            datum:
                DateTime.now().toString().substring(8, 10) +
                "/" +
                DateTime.now().toString().substring(5, 7) +
                "/" +
                DateTime.now().toString().substring(0, 4),
            price: 0,
            label: "Absenden",
            tLabel: "Zertifikat wählen",
            type: 1,
            till:
                DateTime.now()
                    .toString()
                    .replace("2022", "2023")
                    .substring(8, 10) +
                "/" +
                DateTime.now()
                    .toString()
                    .replace("2022", "2023")
                    .substring(5, 7) +
                "/" +
                DateTime.now()
                    .toString()
                    .replace("2022", "2023")
                    .substring(0, 4),
            status: "Aktiv",
            certs: [],
            checked: false,
        };
    }

    componentDidMount() {
        this.getCerts();
    }

    onLoadingClick1() {
        this.setState({ label: "Versendet!" });
        setTimeout(() => {
            this.setState({ domain: "" });
            this.setState({ type: 1 });
            this.setState({ tLabel: "Zertifikat wählen" });
            this.setState({ datum: DateTime.now().toJSDate() });
            this.setState({ status: "" });
            this.setState({ price: 0 });
            this.setState({ label: "Absenden" });
        }, 2000);
    }

    render() {
        return (
            <>
                <div>
                    <h1 className="mt-0">Zertifikat an Pooluser binden</h1>
                    <span className="pr-6">Domain angeben:</span>
                    <InputText
                        className="mb-3"
                        value={this.state.domain}
                        onChange={(e) =>
                            this.setState({ domain: e.target.value })
                        }
                        placeholder={"Domain angeben"}
                    />
                    <br />
                    <span className="pr-8">ID angeben:</span>
                    <InputText
                        className="mb-3"
                        value={this.state.id}
                        onChange={(e) => this.setState({ id: e.target.value })}
                        placeholder={"1"}
                    />
                    <br />
                    <span className="pr-2">Zertifikatstyp wählen:</span>
                    <Dropdown
                        className="mb-3"
                        value={this.state.type}
                        options={this.state.certs}
                        onChange={(e) =>
                            this.setState({
                                type: e.value.nID,
                                tLabel: e.value.cName,
                            })
                        }
                        optionLabel="cName"
                        placeholder={this.state.tLabel}
                    />
                    <br />
                    <span className="pr-5">Datum angeben:</span>
                    {/* <InputMask
            mask="9999/99/99"
            value={this.state.datum}
            placeholder={this.state.datum}
            slotChar="yyyy/mm/dd"
            onChange={(e) => this.setState({ datum: e.target.value })}
          /> */}

                    <Calendar
                        className="mb-3"
                        dateFormat="dd/mm/yy"
                        value={this.state.datum}
                        placeholder={this.state.datum}
                        onChange={(e) => this.setState({ datum: e.value })}
                    />
                    <br />
                    <span className="pr-8 ">Gültig bis :</span>
                    <Calendar
                        className="mb-3"
                        dateFormat="dd/mm/yy"
                        value={this.state.till}
                        placeholder={this.state.till}
                        onChange={(e) => this.setState({ till: e.value })}
                    />
                    {/* <InputMask
            mask="9999/99/99"
            value={this.state.till}
            placeholder={this.state.till}
            slotChar="yyyy/mm/dd"
            onChange={(e) => this.setState({ till: e.target.value })}
          /> */}
                    <br />
                    <span className="pr-6">Preis angeben:</span>
                    <InputNumber
                        className="mb-3"
                        value={this.state.price}
                        onValueChange={(e) =>
                            this.setState({ price: e.target.value })
                        }
                        placeholder={""}
                        mode="currency"
                        currency="EUR"
                        locale="de-DE"
                        minFractionDigits={2}
                    />
                    <br />
                    <div className="mr-6">
                        <span>Status:</span>

                        <Dropdown
                            className="mb-3"
                            value={this.state.status}
                            options={[{ cName: "Aktiv" }, { cName: "Inaktiv" }]}
                            onChange={(e) =>
                                this.setState({
                                    status: e.value.cName,
                                })
                            }
                            optionLabel="cName"
                            placeholder={this.state.status}
                        />
                        <h5>Alfahosting:</h5>
                        <div className="field-checkbox">
                            <Checkbox
                                inputId="binary"
                                checked={this.state.checked}
                                onChange={(e) =>
                                    this.setState({ checked: e.checked })
                                }
                            />
                            <label htmlFor="binary">
                                {this.state.checked
                                    ? "Alfahosting"
                                    : "kein Alfahosting"}
                            </label>
                        </div>
                        <br />
                        <div className="pt-2 ">
                            <Button
                                label={this.state.label}
                                onClick={this.saveCert}
                                disabled={
                                    this.state.type === null ||
                                    this.state.id === null ||
                                    this.state.datum === "" ||
                                    this.state.price === null ||
                                    this.state.domain === ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
    saveCert() {
        this.onLoadingClick1();
        adminAddCertificate(
            this.state.domain,
            this.state.id,
            this.state.price,
            this.state.datum,
            this.state.till,
            this.state.status,
            this.state.type,
            this.state.checked
        );
    }
    async getCerts() {
        let certs: CertList[] = await getCertList();
        this.setState({ certs: certs });
        console.log(this.state.certs);
    }
}
