import React, { Component } from "react";
import { Redirect, withRouter } from "react-router";
import { motion } from "framer-motion";
import "./login.css";
import withContext from "components/shared/withContext";

class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: "",
            password: "",
            email: null,
            secondsToWait: 120,
            securityCode: "",
            codeSuccess: null,
        };
    }

    render() {
        if (this.props.context.user) {
            if (
                this.props.context.user.multiFactor.enrolledFactors.length >
                    0 &&
                this.props.redirect
            ) {
                return <Redirect to="/dashboard" />;
            }
        }
        return (
            <section>
                <motion.div
                    animate={{ scale: 1, opacity: 1 }}
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    className="container"
                >
                    <div className="grid justify-content-center">
                        <div className="xl:col-6 lg:col-12 md:col-9 col-12">
                            <div className="p-card shadow-22 my-5 login-card">
                                <div className="p-card-body 4">
                                    <div className="grid">
                                        <div className="lg:col-6 lg:block bg-login-image"></div>
                                        <div className="lg:col-6 col-12">
                                            <div className="5">
                                                {this.props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </section>
        );
    }
}
export default withContext(withRouter(LoginContainer));
