import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import UserAutocomplete from "./UserAutocomplete";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputMask } from "primereact/inputmask";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { StandardTarifFromDB } from "components/server/TarifMangagement/StandardTarifManagement";
import { ProgressSpinner } from "primereact/progressspinner";
import { throws } from "assert";
import { adminAddTarif, proxGetTarife } from "logik/nodeApiCalls";

type Props = {};

type State = {
    domain: string;
    handle: string;
    vmid: number;
    datum: string;
    user: string;
    price: number;
    label: string;
    typed: string;
    tarif: number;
    ftarif: number;
    runtime: RuntimeOptions[];
    tarife: StandardTarifFromDB[];
};

export interface RuntimeOptions {
    label: string;
    code: number;
}

export default class AddDomain extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.saveDomain = this.saveDomain.bind(this);
        this.onLoadingClick1 = this.onLoadingClick1.bind(this);
        this.getTarife = this.getTarife.bind(this);

        this.state = {
            tarife: [],
            domain: "",
            vmid: 100,
            handle: "Tarif inklusive MLZ wählen",
            datum: DateTime.now().toString(),
            user: "",
            price: 0,
            label: "Absenden",
            typed: "6",
            tarif: 0,
            ftarif: 1,
            runtime: [
                { label: "6", code: 180 },
                { label: "12", code: 365 },
                { label: "24", code: 730 },
                { label: "36", code: 1095 },
            ],
        };
    }

    componentDidMount() {
        this.getTarife();
    }

    onLoadingClick1() {
        this.setState({ label: "Versendet!" });
        setTimeout(() => {
            this.setState({ domain: "" });
            this.setState({ handle: "" });
            this.setState({ datum: DateTime.now().toString() });
            this.setState({ user: "" });
            this.setState({ price: 0 });
            this.setState({ label: "Absenden" });
            this.setState({ typed: "6" });
        }, 2000);
    }

    render() {
        if (this.state.tarife) {
        }
        return (
            <>
                {this.state.tarife.length > 0 ? (
                    <div>
                        <h1>Tarif an Pooluser binden</h1>
                        <span className="pr-2">Node-Namen angeben:</span>
                        <InputText
                            className="mb-3"
                            value={this.state.domain}
                            onChange={(e) =>
                                this.setState({ domain: e.target.value })
                            }
                            placeholder={"Node99-prox"}
                        />
                        <br />
                        <span className="pr-7">VMID angeben:</span>
                        <InputNumber
                            className="mb-3"
                            value={this.state.vmid}
                            min={100}
                            max={999}
                            onValueChange={(e) =>
                                this.setState({ vmid: e.target.value })
                            }
                        />
                        <br />
                        <span className="pr-6">Tarif auswählen :</span>
                        <Dropdown
                            className="mb-3"
                            value={this.state.handle}
                            options={this.state.tarife}
                            onChange={(e) =>
                                this.setState({
                                    handle: e.value.cName,
                                    typed: e.value.nMinMonthRuntime,
                                    tarif: e.value.kTarif,
                                    price: e.value.fPrice,
                                })
                            }
                            optionLabel="cName"
                            placeholder={this.state.handle}
                        />
                        <br />
                        <span className="pr-7">Laufzeit in Monaten:</span>
                        <Dropdown
                            className="mb-3"
                            value={this.state.typed}
                            options={this.state.runtime}
                            onChange={(e) =>
                                this.setState({ typed: e.value.label })
                            }
                            optionLabel="label"
                            placeholder={this.state.typed}
                        />
                        <br />
                        <span className="pr-7 ">Datum angeben :</span>
                        <InputMask
                            className="mb-3"
                            mask="9999/99/99"
                            value={this.state.datum}
                            placeholder={this.state.datum}
                            slotChar="yyyy/mm/dd"
                            onChange={(e) =>
                                this.setState({ datum: e.target.value })
                            }
                        />
                        <br />
                        <span className="pr-8">Preis angeben:</span>
                        <InputNumber
                            className="mb-3"
                            value={this.state.price}
                            onValueChange={(e) =>
                                this.setState({ price: e.target.value })
                            }
                            placeholder={this.state.price.toString()}
                            mode="currency"
                            currency="EUR"
                            locale="de-DE"
                            minFractionDigits={2}
                        />
                        <br />
                        <div>
                            <span>Pool angeben:</span>
                            <UserAutocomplete
                                onSelect={(user: string) =>
                                    this.setState({ user: user })
                                }
                            />
                            <div className="pt-2 ">
                                <Button
                                    label={this.state.label}
                                    onClick={this.saveDomain}
                                    disabled={
                                        this.state.user === "" ||
                                        this.state.handle === "" ||
                                        this.state.datum === "" ||
                                        this.state.price === null ||
                                        this.state.domain === ""
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <ProgressSpinner />
                )}
            </>
        );
    }
    saveDomain() {
        this.onLoadingClick1();
        adminAddTarif(
            this.state.domain,
            this.state.vmid,
            this.state.datum,
            this.state.price,
            this.state.user,
            this.state.tarif,
            this.state.ftarif
        );
    }
    async getTarife() {
        let tarife: StandardTarifFromDB[] = await proxGetTarife();
        this.setState({ tarife: tarife });
        console.log(this.state.tarife);
    }
}
