import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { withRouter } from "react-router";
import { motion } from "framer-motion";
import "./login.css";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Dialog } from "primereact/dialog";
import withContext from "components/shared/withContext";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: "darius.rohde@gmx.de",
            password: "",
            email: null,
            secondsToWait: 120,
            securityCode: "",
            codeSuccess: null,
            show2FA: false,
            angemeldetBleiben: false,
        };
        this.login = this.login.bind(this);
        this.resolve2FA = this.resolve2FA.bind(this);
    }

    componentDidMount() {
        try {
            firebase.auth().useDeviceLanguage();
            //  firebase.auth().tenantId = "modernsolution-224000"
            this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "sign-in-button",
                {
                    size: "invisible",
                    callback: function (response) {
                        console.log("recaptcha suxccsses");
                        // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                        this.login();
                    },
                }
            );
        } catch (err) {}
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        return (
            <>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">
                        {this.props.redirect ? (
                            <>Willkommen zurück</>
                        ) : (
                            <>Authentifizieren Sie sich</>
                        )}
                    </h1>
                </div>
                {new URLSearchParams(this.props.location.search).get(
                    "register"
                ) && (
                    <motion.div
                        className="mt-2 mb-2"
                        animate={{ x: 0 }}
                        initial={{ x: -2000 }}
                        transition={{ duration: 1, type: "spring" }}
                    >
                        <Message
                            severity="success"
                            text="Wir haben Ihnen eine Mail zur Verifizierung Ihres Accounts zugeschickt. Bitte überprüfen Sie auch Ihren Spamordner."
                        ></Message>
                    </motion.div>
                )}
                {this.state.email !== null && this.state.email !== false && (
                    <motion.div
                        className="mt-2 mb-2"
                        animate={{ x: 0 }}
                        initial={{ x: -2000 }}
                        transition={{ duration: 1, type: "spring" }}
                    >
                        <Message
                            severity="info"
                            text={
                                "Ihnen wurde ein Code an die Mailadresse " +
                                this.state.email +
                                " gesendet  (gilt noch: " +
                                this.state.secondsToWait +
                                " Sekunden)"
                            }
                        />
                        <br />
                    </motion.div>
                )}
                {this.state.email === false && (
                    <Message
                        severity="error"
                        text={"E-Mail oder Passwort sind falsch."}
                    />
                )}
                <form className="user">
                    <Messages ref={(el) => (this.messages = el)} />
                    <div className="form-group ">
                        <div className="form-group mb-2">
                            <InputText
                                value={this.state.user}
                                onChange={(e) =>
                                    this.setState({ user: e.target.value })
                                }
                                type="text"
                                className="w-full"
                                id="username"
                                aria-describedby="emailHelp"
                                placeholder="Email oder Nutzernamen"
                                disabled={
                                    this.state.email !== null &&
                                    this.state.email !== false
                                }
                            />
                        </div>
                        <div className="form-group mb-3">
                            <InputText
                                value={this.state.password}
                                onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                }
                                type="password"
                                className="w-full"
                                id="exampleInputPassword"
                                placeholder="Passwort"
                                disabled={
                                    this.state.email !== null &&
                                    this.state.email !== false
                                }
                            />
                        </div>
                        {this.state.email !== null &&
                            this.state.email !== false && (
                                <motion.div
                                    className="mt-2 mb-3"
                                    animate={{ opacity: 1, scale: 1 }}
                                    initial={{ opacity: 0, scale: 0 }}
                                    transition={{ duration: 1, type: "spring" }}
                                >
                                    <InputText
                                        value={this.state.securityCode}
                                        onChange={(e) =>
                                            this.setState({
                                                securityCode: e.target.value,
                                            })
                                        }
                                        type="number"
                                        className="mb-2 w-full"
                                        id="securitycode"
                                        aria-describedby="emailHelp"
                                        placeholder="Securitycode"
                                    />
                                </motion.div>
                            )}
                        <div className="field flex align-items-center">
                            <Checkbox
                                inputId="angemeldetBleiben"
                                name="city"
                                value="Ja"
                                onChange={(e) =>
                                    this.setState({
                                        angemeldetBleiben: e.checked,
                                    })
                                }
                                checked={this.state.angemeldetBleiben}
                            />
                            <label htmlFor="angemeldetBleiben">
                                Angemeldet bleiben
                            </label>
                        </div>
                        <Button
                            type="button"
                            label="Login"
                            id="sign-in-button"
                            className="p-button-rounded w-full mb-2"
                            onClick={this.login}
                        />
                    </div>
                </form>
                <hr />
                <div className="text-center">
                    <Link className="text-center" to="/requestNewPw">
                        Passwort vergessen?
                    </Link>
                </div>
                {this.props.redirect && (
                    <div className="text-center">
                        <Link className="text-center" to="/registration">
                            Erstellen Sie einen Account!
                        </Link>
                    </div>
                )}
                <Dialog
                    header="2FA"
                    visible={this.state.show2FA}
                    modal={true}
                    closable={false}
                    closeOnEscape={false}
                    onHide={() => this.setState({ show2FA: false })}
                >
                    <div className="p-text-center">
                        <InputText
                            value={this.state.securityCode}
                            onChange={(e) =>
                                this.setState({
                                    securityCode: e.target.value,
                                })
                            }
                            type="number"
                        />
                        <Button
                            label="Code Bestätigen"
                            onClick={this.resolve2FA}
                            disabled={
                                this.state.securityCode.length !== 6 ||
                                isNaN(parseFloat(this.state.securityCode))
                            }
                        />
                    </div>
                </Dialog>
            </>
        );
    }

    async resolve2FA() {
        var cred = firebase.auth.PhoneAuthProvider.credential(
            this.state.verificationId,
            this.state.securityCode
        );
        var multiFactorAssertion =
            firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        try {
            await this.state.resolver.resolveSignIn(multiFactorAssertion);

            if (this.props.redirect) {
                this.props.history.push("/");
            }

            await this.props.onLogin();

            console.log(firebase.auth().currentUser);
            axios.defaults.headers = {
                Auth: await firebase.auth().currentUser.getIdToken(),
            };

            nodeAxios.defaults.headers = {
                Authorization: await firebase.auth().currentUser.getIdToken(),
            };
            console.log(await firebase.auth().currentUser.getIdToken());
        } catch (err) {
            if (err.code === "auth/invalid-verification-code") {
                this.toast.show({
                    severity: "error",
                    summary: "Falscher Code",
                    detail: "Bitte überprüfen Sie Ihre Eingabe",
                });
            } else {
                console.log(err);
                /* this.toast?.show({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Es ist ein Fehler aufgetreten",
                }); */
            }
        }
    }

    async login() {
        try {
            var resolver;
            var history = this.props.history;
            var persistence;
            if (this.state.angemeldetBleiben) {
                persistence = firebase.auth.Auth.Persistence.LOCAL;
            } else {
                persistence = firebase.auth.Auth.Persistence.SESSION;
            }
            await firebase.auth().setPersistence(persistence);
            firebase
                .auth()
                .signInWithEmailAndPassword(
                    this.state.user,
                    this.state.password
                )
                .then((userCredential) => {
                    var user = userCredential.user;
                    console.log(user);
                    console.log("success ohne 2 Faktor");
                    if (user.multiFactor.enrolledFactors.length === 0) {
                        history.push("/activateMFA");
                    } else {
                    }
                })
                .catch(async (error) => {
                    console.log(error);
                    if (error.code === "auth/multi-factor-auth-required") {
                        resolver = error.resolver;
                        // Ask user which second factor to use.
                        if (
                            resolver.hints[0].factorId ===
                            firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
                        ) {
                            var phoneInfoOptions = {
                                multiFactorHint: resolver.hints[0],
                                session: resolver.session,
                            };
                            console.log(phoneInfoOptions);
                            var phoneAuthProvider =
                                new firebase.auth.PhoneAuthProvider();
                            // Send SMS verification code
                            var recaptchaVerifier =
                                new firebase.auth.RecaptchaVerifier(
                                    "sign-in-button",
                                    {
                                        size: "invisible",
                                        callback: function (response) {
                                            console.log("recaptcha suxccsses");
                                            // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                                            this.login();
                                        },
                                    }
                                );
                            try {
                                var verificationId =
                                    await phoneAuthProvider.verifyPhoneNumber(
                                        phoneInfoOptions,
                                        recaptchaVerifier
                                    );

                                // Ask user for the SMS verification code.
                                this.setState({
                                    show2FA: true,
                                    verificationId: verificationId,
                                    resolver: resolver,
                                });
                            } catch (err) {
                                console.log(err);
                            }
                        } else {
                            // Unsupported second factor.
                        }
                    } else if (error.code === "auth/wrong-password") {
                        this.toast.show({
                            severity: "error",
                            summary: "Falsches Passwort",
                            detail: "Bitte überprüfen Sie Ihre Eingabe",
                        });
                    } else if (error.code === "auth/missing-email") {
                        this.toast.show({
                            severity: "error",
                            summary: "Ungültige Email",
                            detail: "Bitte überprüfen Sie Ihre Eingabe",
                        });
                    } else if (error.code === "auth/invalid-email") {
                        this.toast.show({
                            severity: "error",
                            summary: "Ungültige Email",
                            detail: "Bitte überprüfen Sie Ihre Eingabe",
                        });
                    } else if (error.code === "auth/too-many-requests") {
                        this.toast.show({
                            life: 10000,
                            severity: "error",
                            summary: "Ihr Account wurde temporär gesperrt",
                            detail: "Ihr Account ist vorrübergehend wegen zu vieler Fehlversuche gesperrt, um die Sperrung sofort aufzuheben müssen Sie Ihr Kennwort zurücksetzen.",
                        });
                    }
                });
        } catch (err) {}
    }
}
export default withContext(withRouter(Login));
