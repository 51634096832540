import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import firebase from "firebase/compat/app";

import { QueryClient, QueryClientProvider } from "react-query";

firebase.initializeApp({
    apiKey: "AIzaSyAvZi1y-T8d0cxVKt31bnG6dSYnFF6A7_8",
    authDomain: "modernsolution-224000.firebaseapp.com",
    projectId: "modernsolution-224000",
    storageBucket: "modernsolution-224000.appspot.com",
    messagingSenderId: "63109641714",
    appId: "1:63109641714:web:220a8bc514f8d2a9bf676b",
});
const queryClient = new QueryClient();
ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <App />
                {/*   <ReactQueryDevtools  initialIsOpen={false} /> */}
            </QueryClientProvider>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
