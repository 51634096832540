import { Vminfo } from "logik/nodeApiCalls";
import { utilitiesParseProxmoxValues } from "logik/utilities";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { BiTachometer } from "react-icons/bi";
import { GrStorage } from "react-icons/gr";
import ServerResizeDiskDialog from "./ServerResizeDiskDialog";

type Props = {
    vmid: number;
    vminfo: Vminfo;
};

const ServerDiskList = ({ vmid, vminfo }: Props) => {
    const [activeDiskForResizing, setActiveDiskForResizing] = useState<{
        diskID: string;
        size: number;
    }>({ diskID: "", size: 0 });
    const [resizeDiskDialogVisibible, setResizeDiskDialogVisibible] =
        useState<boolean>(false);

    useEffect(() => {
        buildDiskList();
    }, []);

    const buildDiskList = () => {
        const diskKeys = Object.keys(vminfo).filter(
            (key) => key.includes("scsi") && key !== "scsihw"
        );
        const disks = diskKeys.map((key) => {
            console.log(key);
            return utilitiesParseProxmoxValues(vminfo[key], key);
        });
        return disks;
    };

    return (
        <>
            <DataTable value={buildDiskList()} key="key">
                <Column
                    header={
                        <>
                            <GrStorage className="mr-2" />
                            Größe
                        </>
                    }
                    field="size"
                    body={(rowdata) => (
                        <Button
                            className="p-button-text p-button-info"
                            icon="pi pi-cog"
                            label={`${rowdata.size}B`}
                            onClick={() => {
                                setActiveDiskForResizing({
                                    diskID: rowdata.key,
                                    size: parseInt(
                                        rowdata.size.replace("G", "")
                                    ),
                                });
                                setResizeDiskDialogVisibible(true);
                            }}
                        />
                    )}
                />
                <Column
                    header={
                        <>
                            <BiTachometer className="mr-2" />
                            Performance
                        </>
                    }
                    body={(e) =>
                        e.iops_wr === "500" ? (
                            <span>Normal Performance</span>
                        ) : (
                            <span>High Performance</span>
                        )
                    }
                />
            </DataTable>
            <ServerResizeDiskDialog
                id={vmid}
                diskID={activeDiskForResizing.diskID}
                diskSizeNow={activeDiskForResizing.size}
                visible={resizeDiskDialogVisibible}
                onHide={() => {
                    setResizeDiskDialogVisibible(false);
                    setActiveDiskForResizing({
                        diskID: "",
                        size: 0,
                    });
                }}
            />
        </>
    );
};

export default ServerDiskList;
