import axios from "axios";
import { updateVmNames } from "./nodeApiCalls";

// export const proxGetServerList = async (pool: undefined) => {
//     var serverlist: any[] = [];
//     await axios
//         .post(`server/getServerlist.php`, {
//             pool: pool,
//         })
//         .then((res) => {
//             if (res.data.length > 0) {
//                 serverlist = Object.values(res.data);
//             }
//         });
//     return serverlist.sort((a, b) => (a.vmid > b.vmid ? 1 : -1));
// };

// export const proxGetVmInfo = async (nodename: string, vmid: any) => {
//     const response = await axios.post("server/vm/getVmInfo.php", {
//         nodename: nodename,
//         vmid: vmid,
//     });
//     return response;
// };

// export const proxGetTarife = async () => {
//     const response = await axios.post("server/tarife/getTarife.php");
//     return response.data;
// };

/* export const proxGetCurrentFlexTarif = async () => {
    const response = await axios.post("server/tarife/getCurrentFlexTarif.php");
    return response.data;
};
 */
export const proxSaveNewTarif = async (
    name: string,
    beschreibung: string,
    mvl: number,
    preis: number,
    gbRam: number,
    cpu: number,
    speicherHP: number,
    speicherNP: number,
    templatevmID: string
) => {
    const response = await axios.post("server/tarife/saveNewTarif.php", {
        name: name,
        beschreibung: beschreibung,
        mvl: mvl,
        preis: preis,
        gbRam: gbRam,
        cpu: cpu,
        speicherHP: speicherHP,
        speicherNP: speicherNP,
        templatevmID: templatevmID,
    });
    return response.data;
};

export const proxSaveNewFlexTarif = async (
    cName: string,
    cBeschreibung: string,
    fRamPrice: number,
    fCpuPrice: number,
    fSpeicherHighPerformancePrice: number,
    fSpeicherNormalPerformancePrice: number
) => {
    const response = await axios.post("server/tarife/saveNewFlexTarif.php", {
        cName: cName,
        cBeschreibung: cBeschreibung,
        fRamPrice: fRamPrice,
        fCpuPrice: fCpuPrice,
        fSpeicherHighPerformancePrice: fSpeicherHighPerformancePrice,
        fSpeicherNormalPerformancePrice: fSpeicherNormalPerformancePrice,
    });
    return response.data;
};

export const proxGetAllTarife = async () => {
    const response = await axios.post(
        "server/tarife/getAllStandardTarifManagement.php"
    );
    return response.data;
};

export const proxGetAllFlexTarife = async () => {
    const response = await axios.post(
        "server/tarife/getAllFlexTarifManagement.php"
    );
    return response.data;
};

export const proxChangeTarifDefault = async (
    tarif: number,
    isDefault: string
) => {
    const response = await axios.post("server/tarife/changeTarifDefault.php", {
        tarif: tarif,
        isDefault: isDefault,
    });
    return response.data;
};

export const proxChangeFlexTarifDefault = async (
    tarif: number,
    isDefault: string
) => {
    const response = await axios.post(
        "server/tarife/changeFlexTarifDefault.php",
        {
            tarif: tarif,
            isDefault: isDefault,
        }
    );
    return response.data;
};

/* export const proxGetTarifForVm = async (vmid: string) => {
    const response = await axios.post("server/tarife/getTarifForVM.php", {
        vmid: vmid,
    });
    return response.data;
}; */

export const proxGetNotifications = async (nodename: any, vmid: any) => {
    const response = await axios.post(
        "general/notification/getNotification.php"
    );
    return response.data;
};

/* export const proxBackUpServer = async (nodename: any, vmid: any) => {
    var response = await axios.post(`server/vm/startVmBackup.php`, {
        nodename: nodename,
        vmid: vmid,
    });
    console.log(response);
    return response;
};
 */
/* export const proxGetPools = async () => {
    var response = await axios.post(`server/pool/getPools.php`);
    var pools: any[] = [];
    Object.values(response.data).forEach((pool: any) => {
        pools.push(pool.kcPool);
    });
    return pools;
};
 */
export const proxGetCustomerPools = async () => {
    var response = await axios.post(`server/pool/getCustomerPools.php`);
    var pools: any[] = [];
    Object.values(response.data).forEach((pool: any) => {
        pools.push(pool.kcPool);
    });
    return pools;
};

/* export const proxGetVlans = async (pool: any) => {
    var response = await axios.post(`server/vlans/getVlans.php`, {
        pool: pool,
    });
    console.log(response);
    var vlans: { vlan: any; cFrontendName: any }[] = [];
    try {
        Object.values(response.data).forEach((vlan: any) => {
            vlans.push({ vlan: vlan.kVlan, cFrontendName: vlan.cFrontendName });
        });

        return vlans;
    } catch (error) {
        return [];
    }
};
 */
/* export const datalogsGetSnapshots = async (vmid: string) => {
    var response = await axios.post(`server/tarife/getSnapshotsForVM.php`, {
        vmid: vmid,
    });
    return response.data;
};
 */
export const proxCreateBackupJob = async (
    nodename: any,
    vmid: any,
    interval: null
) => {
    var response = await axios.post(`server/vm/backup/createBackupJob.php`, {
        nodename: nodename,
        vmid: vmid,
        interval: interval,
    });
    console.log(response.data);
    return response.data;
};

/* export const proxGetBackups = async (vmid: any) => {
    var response = await axios.post(`server/vm/backup/getBackups.php`, {
        vmid: vmid,
    });

    return response.data.data;
};
 */
/* export const proxRestoreBackup = async (
    vmid: any,
    cTime: any,
    name: any,
    toNewVm: any
export const proxRestoreBackup = async (
  vmid: any,
  cTime: any,
  name: any,
  toNewVm: any
) => {
  var response = await axios.post(`server/vm/backup/restoreBackup.php`, {
    vmid: vmid,
    cTime: cTime,
    name: name,
    toNewVm: toNewVm ? "1" : "0",
  });
  return response.data.data;
};
 */
/* export const proxGetFilesForFileRestore = async (
  vmid: any,
  cTime: any,
  path: string
) => {
  var response = await axios.post(`server/vm/backup/fileRestore/getFiles.php`, {
    vmid: vmid,
    cTime: cTime,
    path: path,
  });

  return response.data.data;
};
 */
/* export const proxDownloadFilesForFileRestore = async (
    vmid: any,
    cTime: any,
    path: any,
    leaf: any
) => {
    var response = await axios.post(
        `server/vm/backup/fileRestore/downloadFile.php`,
        {
            vmid: vmid,
            cTime: cTime,
            leaf: leaf,
            path: path,
        }
    );
    if (typeof response.data === "string") {
        return null;
    }

    return response.data;
}; */

export const proxGetBackupJobs = async (vmid: any) => {
    var response = await axios.post(`server/vm/backup/getBackupJobs.php`, {
        vmid: vmid,
    });
    return response.data;
};

/* export const proxGetVerlauf = async (vmid: any) => {
    var response = await axios.post(`server/vm/getVmVerlauf.php`, {
        vmid: vmid,
    });
    return response.data;
}; */

/* export const proxGetImages = async () => {
    var response = await axios.post(`server/images/getImages.php`);
    return response.data;
}; */
/* export const proxGetRrdData = async (
    vmid: any,
    nodename: any,
    timeframe: any
) => {
    var response = await axios.post(`server/vm/rrdData/getRrdData.php`, {
        vmid: vmid,
        nodename: nodename,
        timeframe: timeframe,
    });
    return response.data;
}; */

/* export const proxStartServer = async (vmid: any) => {
    const response = await axios.post("server/vm/basic/startVm.php", {
        vmid: vmid,
    });
    return response.data.data;
}; */

/* export const proxStopServer = async (vmid: any) => {
    const response = await axios.post("server/vm/basic/shutDownVm.php", {
        vmid: vmid,
    });
    return response.data.data;
}; */

/* export const proxChangeVmSpec = async (
    vmid: any,
    spec: string,
    value: number
) => {
    const response = await axios.post("server/vm/changeVMSpecs.php", {
        vmid: vmid,
        spec: spec,
        value: value,
    });
    return response.data.data;
}; */

/* export const proxCreateSnapshotAdmin = async (vmid: string) => {
    const response = await axios.post(
        "server/vm/admin/createSnapshotAdmin.php",
        {
            vmid: vmid,
        }
    );
    return response.data.data;
};
 */
/* export const proxRebootServer = async (vmid: any) => {
    const response = await axios.post("server/vm/basic/rebootServer.php", {
        vmid: vmid,
    });
    return response.data.data;
};
 */
export const authUserFree = async (user: any) => {
    var response = await axios.post(`general/registration/isUserFree.php`, {
        user: user,
    });
    return response.data;
};

export const authRegistrate = async (username: string, email: string) => {
    var response = await axios.post(`general/registration/registration.php`, {
        username: username,
        email: email,
    });
    console.log(response.data);
    return response.data;
};

export const authConfirmNewPasswordRequest = async (
    email: string | null,
    code: string | null
) => {
    var response = await axios.post(
        `general/renewPassword/confirmNewPasswordRequest.php`,
        {
            email: email,
            code: code,
        }
    );
    return response.data;
};

export const authChangePassword = async (
    email: string | null,
    code: string | null,
    password: string
) => {
    var response = await axios.post(
        `general/renewPassword/changePassword.php`,
        {
            email: email,
            code: code,
            password: password,
        }
    );
    return response.data;
};

export const authRequestChangePassword = async (email: any) => {
    var response = await axios.post(
        `general/renewPassword/requestNewPassword.php`,
        {
            email: email,
            url: window.location.origin,
        }
    );
    return response.data;
};

export const adminGetKamp = async () => {
    var response = await axios.post(`administration/getKampView.php`);
    return response.data;
};

export const adminGetUser = async () => {
    var response = await axios.get(
        `administration/manuelleEinpflegung/getUsers.php`
    );
    return response.data;
};

export const adminChangeKamp = async (
    value: any,
    field: any,
    datacenter: any
) => {
    var response = await axios.post(`administration/updateKampView.php`, {
        value: value,
        column: field,
        datacenter: datacenter,
    });
    console.log(response.data);
    return response.data;
};

export const adminGetKampTarife = async () => {
    var response = await axios.post(`administration/getKampTarifList.php`);
    return response.data;
};

export const domainGetWhoIs = async (domain: string) => {
    var response = await axios.post(`domains/getDomainDetails.php`, {
        domain: domain,
    });
    return response.data;
};

export const domainGetHandles = async () => {
    var response = await axios.post(`domains/cloudFrontend/getHandles.php`);
    return response.data;
};

export const domainCreateHandles = async (handle: any) => {
    var response = await axios.post(
        `domains/cloudFrontend/createHandle.php`,
        handle
    );
    return response.data;
};

export const domainCreateDomain = async (
    handle: string,
    invoiceAdress: number,
    payment: number,
    basketChecksum: string
) => {
    var response = await axios.post(`domains/cloudFrontend/createDomain.php`, {
        handle: handle,
        invoiceAdress: invoiceAdress,
        basketChecksum: basketChecksum,
        payment: payment,
    });
    return response.data;
};

export const adminDeleteKamp = async (datacenter: any) => {
    var response = await axios.post(`administration/deleteKampTarif.php`, {
        datacenter: datacenter,
    });
    console.log(response.data);
    return response.data;
};

export const proxCreateNewVMfromTemplate = async (
    vmname: string,
    nodename: string,
    pool: { pool: any },
    vlan: { vlan: any },
    template: any
) => {
    var response = await axios.post(
        `server/vm/createVM/createVMfromTemplate.php`,
        {
            vmname: vmname,
            nodename: nodename,
            pool: pool.pool,
            vlan: vlan.vlan,
            template: template,
        }
    );
    return response.data;
};

export const saveFeedback = async (subject: string, content: string) => {
    const response = await axios.post("general/feedback/saveFeedback.php", {
        subject: subject,
        content: content,
    });
    return response.data;
};

export const saveInvoiceAdress = async (
    firma: string,
    vorname: string,
    name: string,
    strasse: string,
    postleitzahl: string,
    stadt: string,
    land: string,
    telefon: string,
    email: string
) => {
    const response = await axios.post(
        "payment/invoiceAdresses/saveInvoiceAdress.php",
        {
            firma: firma,
            vorname: vorname,
            name: name,
            strasse: strasse,
            postleitzahl: postleitzahl,
            stadt: stadt,
            land: land,
            telefon: telefon,
            email: email,
        }
    );
    return response.data;
};

export const getPaymentInformaion = async () => {
    var response = await axios.post(
        `payment/paymentInformation/getPaymentInformations.php`
    );
    return response.data;
};

export const savePaymentInformation = async (
    zahlungsart: string,
    iban: string,
    kontoinhaber: string,
    bic: string
) => {
    const response = await axios.post(
        "payment/paymentInformation/savePaymentInformation.php",
        {
            zahlungsart: zahlungsart,
            iban: iban,
            kontoinhaber: kontoinhaber,
            bic: bic,
        }
    );
    return response.data;
};

export const getInvoiceAdresses = async () => {
    var response = await axios.post(
        `payment/invoiceAdresses/getInvoiceAdresses.php`
    );
    return response.data;
};

export const getDomainBasket = async (checksum: string | null) => {
    var response = await axios.post(`domains/cloudFrontend/getBasket.php`, {
        checksum: checksum,
    });
    return response.data;
};

// export const adminAddDomain = async (
//   domain: string,
//   handle: string,
//   datum: string,
//   price: number,
//   user: string,
//   typed: string,
//   tld: string
// ) => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/addDomain.php",
//     {
//       domain: domain,
//       handle: handle,
//       datum: datum,
//       price: price,
//       user: user,
//       typed: typed,
//       tld: tld,
//     }
//   );
//   return response.data;
// };

// export const adminPoolList = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/poolList.php",
//     {}
//   );
//   return response.data;
// };

// export const adminGetTLD = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/tldCount.php",
//     {}
//   );
//   return response.data;
// };

// export const adminGetDomains = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/domainsCard.php",
//     {}
//   );
//   return response.data;
// };

// export const adminGetYearly = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/yearlyCard.php",
//     {}
//   );
//   return response.data;
// };

// export const adminAddTarif = async (
//   domain: string,
//   vmid: number,
//   datum: string,
//   preis: number,
//   user: string,
//   tariff: number,
//   ftariff: number
// ) => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/addTariff.php",
//     {
//       domain: domain,
//       vmid: vmid,
//       datum: datum,
//       preis: preis,
//       user: user,
//       tariff: tariff,
//       ftariff: ftariff,
//     }
//   );
//   return response.data;
// };

// export const adminAddCertificate = async (
//   domain: string,
//   id: string,
//   price: number,
//   datum: string,
//   till: string,
//   status: string,
//   type: number,
//   checked: boolean
// ) => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/addCert.php",
//     {
//       domain: domain,
//       id: id,
//       price: price,
//       datum: datum,
//       till: till,
//       status: status,
//       type: type,
//       checked: checked,
//     }
//   );
//   return response.data;
// };

// export const adminGetCertYearly = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/certYearlyCard.php",
//     {}
//   );
//   return response.data;
// };

// export const adminGetCertCount = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/certCountCard.php",
//     {}
//   );
//   return response.data;
// };

// export const adminGetCertTypes = async () => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/certCount.php",
//     {}
//   );
//   return response.data;
// };

// export const adminShowCert = async () => {
//   const response = await axios.post(
//     "administration/manuelleEinpflegung/adminShowCert.php"
//   );
//   return response.data;
// };

// export const certList = async () => {
//   const response = await axios.post(
//     "administration/manuelleEinpflegung/certList.php"
//   );
//   return response.data;
// };

// export const adminShowTariff = async () => {
//   const response = await axios.post(
//     "administration/manuelleEinpflegung/showTariff.php"
//   );
//   return response.data;
// };

// export const giveUserCert = async (user: string) => {
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/showUserCert.php",
//     {
//       user: user,
//     }
//   );
//   return response.data;
// };

// export const giveUserTarif = async (user: string) => {
//   console.log(user);
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/showUserTarif.php",
//     {
//       user: user,
//     }
//   );
//   return response.data;
// };

// export const giveUserAbo = async (user: string) => {
//   console.log(user);
//   var response = await axios.post(
//     "administration/manuelleEinpflegung/showUserAbo.php",
//     {
//       user: user,
//     }
//   );
//   return response.data;
// };

/* export const proxGetTarife = async () => {
    const res = await axios.post("server/tarife/getTarife.php");
    return res.data;
};
 */
