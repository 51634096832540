import RightChecker from "components/shared/RightChecker";
import { Badge } from "primereact/badge";
import React, { useRef } from "react";
import { StyleClass } from "primereact/styleclass";

type Props = {
    setAdminMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar = ({ setAdminMenuVisible }: Props) => {
    const btnRef54 = useRef(null);
    const btnRef55 = useRef(null);
    return (
        <div className="surface-overlay py-1 px-6 flex align-items-center justify-content-between relative lg:static">
            <StyleClass
                nodeRef={btnRef54}
                selector="#app-sidebar-12"
                enterClassName="hidden"
                enterActiveClassName="fadeinleft"
                leaveToClassName="hidden"
                leaveActiveClassName="fadeoutleft"
                hideOnOutsideClick
            >
                <a
                    ref={btnRef54}
                    className="p-ripple cursor-pointer block lg:hidden text-700"
                >
                    <i className="pi pi-bars text-4xl"></i>
                </a>
            </StyleClass>
            <img
                id="logo"
                className="p-d-block p-m-auto mb-3"
                alt="logo"
                src="img/logo_weiss_svg.svg"
                height="50"
            />
            <StyleClass
                nodeRef={btnRef55}
                selector="@next"
                enterClassName="hidden"
                leaveToClassName="hidden"
                hideOnOutsideClick
            >
                <a
                    ref={btnRef55}
                    className="p-ripple cursor-pointer block lg:hidden text-700"
                >
                    <i className="pi pi-ellipsis-v text-2xl"></i>
                </a>
            </StyleClass>
            <ul className="list-none p-3 lg:p-0 m-0 lg:align-items-center select-none flex-column lg:flex-row absolute hidden lg:flex lg:static surface-overlay right-0 top-100 z-1 shadow-2 lg:shadow-none">
                <li className="lg:mr-1">
                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:border-circle">
                        <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span className="block lg:hidden font-medium">
                            Inbox
                        </span>
                    </a>
                </li>
                <li className="lg:mr-1">
                    <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:border-circle">
                        <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                            <Badge severity="danger" />
                        </i>
                        <span className="block lg:hidden font-medium">
                            Notifications
                        </span>
                    </a>
                </li>
                <RightChecker right="10000">
                    <li className="lg:mr-1">
                        <a
                            onClick={() => setAdminMenuVisible(true)}
                            className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:border-circle"
                        >
                            <i className="pi pi-cog text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"></i>
                            <span className="block lg:hidden font-medium">
                                Notifications
                            </span>
                        </a>
                    </li>
                </RightChecker>
            </ul>
        </div>
    );
};

export default Navbar;
