import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import { AutoComplete } from "primereact/autocomplete";
import { adminGetUser, getAdditionalTarifsByPool } from "logik/nodeApiCalls";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteButtonGlobal from "./DeleteButtonGlobal";
import { showUserAbo, showUserCert, showUserTariff } from "logik/nodeApiCalls";
import IsoDateParser from "../../shared/IsoDateParser";
// import DeleteButtonGlobal from "./DeleteButtonGlobal";
// import PoolGrouper from "./PoolGrouper";
//
type Props = {};

const FullUserData = (props: Props) => {
    const [value, setValue]: any = useState("");
    const [users, setUsers] = useState([""]);
    const [listedUser, setListedUser] = useState("User-Übersicht");
    const [selectedUser, setselectedUser] = useState("");
    const [selectedObjectUser, setselectedObjectUser] = useState({});
    const [filteredUser, setfilteredUser] = useState(["string"]);
    const [certs, setCerts] = useState([]);
    const [tarifs, setTarifs] = useState([]);
    const [abos, setAbos] = useState([]);
    const [additionalTarifs, setAdditionalTarifs] = useState([]);

    const searchUser = (event: any) => {
        setTimeout(() => {
            let _filteredUsers: string[];
            if (!event.query.trim().length) {
                _filteredUsers = [...users];
            } else {
                _filteredUsers = users.filter((user: any) => {
                    return user.cFrontendName
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }

            setfilteredUser(_filteredUsers);
        }, 250);
    };

    useEffect(() => {
        adminGetUser().then((data) => setUsers(data));
        setselectedObjectUser({ cFrontendName: "" });
    }, []);

    useEffect(() => {
        console.log(selectedUser);
        update();
    }, [selectedUser]);

    const update = async () => {
        setAbos(await showUserAbo(selectedUser));
        setCerts(await showUserCert(selectedUser));
        setTarifs(await showUserTariff(selectedUser));
        setAdditionalTarifs(await getAdditionalTarifsByPool(selectedUser));
    };

    return (
        <div>
            <div className="flex">
                <AutoComplete
                    value={selectedObjectUser}
                    suggestions={filteredUser}
                    completeMethod={searchUser}
                    placeholder="Wählen sie einen User"
                    dropdown
                    field="cFrontendName"
                    onChange={(e) => {
                        setselectedObjectUser(e.value);
                        if (typeof e.value === "object" && e.value !== null) {
                            setselectedUser(e.value.cFrontendName);
                        }
                    }}
                    aria-label="Wähle einen User"
                />
                <div className="ml-auto">
                    <DeleteButtonGlobal
                        certs={certs}
                        tarifs={tarifs}
                        abos={abos}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="col-12 xl:col-6">
                    <Fieldset legend="Domains" toggleable>
                        <DataTable
                            stripedRows
                            value={abos}
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                        >
                            <Column field="cDescription" header="Name" />
                            <Column field="cType" header="Typ" />
                            <Column
                                field="dErstellt"
                                header="Erstellt"
                                body={(rowData) => (
                                    <IsoDateParser
                                        isoDate={rowData.dErstellt}
                                    />
                                )}
                            />
                            <Column
                                field="fPreisProAbrechnugszeitraum"
                                header="Preis"
                                body={(rowData: any) => (
                                    <>
                                        {rowData.fPreisProAbrechnugszeitraum && (
                                            <>
                                                {rowData.fPreisProAbrechnugszeitraum.toFixed(
                                                    2
                                                )}{" "}
                                                €
                                            </>
                                        )}
                                    </>
                                )}
                            />
                        </DataTable>
                    </Fieldset>
                </div>
                <div className="col-12 xl:col-6">
                    <Fieldset legend="Zertifikate" toggleable>
                        <DataTable
                            stripedRows
                            value={certs}
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                        >
                            <Column field="nID" header="ID" />
                            <Column field="Domain" header="Domain" />
                            <Column field="cName" header="Typ" />
                        </DataTable>
                    </Fieldset>
                </div>
            </div>
            <div className="grid">
                <div className="col-12 xl:col-6">
                    <Fieldset legend="Tarife" toggleable>
                        <DataTable
                            stripedRows
                            value={tarifs}
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                        >
                            <Column field="cVmid" header="ID" />
                            <Column field="cNutzer" header="Pool"></Column>
                            <Column field="cVMname" header="Vm-Name"></Column>
                            <Column
                                field="cDate"
                                header="Erstelldatum"
                                body={(rowData) => (
                                    <IsoDateParser isoDate={rowData.cDate} />
                                )}
                            />
                            <Column field="cTariff" header="Tarifname"></Column>
                            <Column
                                field="fPreis"
                                header="Preis"
                                body={(rowData: any) => (
                                    <>
                                        {rowData.fPreis && (
                                            <>{rowData.fPreis.toFixed(2)} €</>
                                        )}
                                    </>
                                )}
                            ></Column>
                        </DataTable>
                    </Fieldset>
                </div>
                <div className="col-12 xl:col-6">
                    <Fieldset legend="Zusatztarife" toggleable>
                        <DataTable
                            stripedRows
                            value={additionalTarifs}
                            paginator
                            rows={5}
                            responsiveLayout="scroll"
                        >
                            <Column field="cName" header="Tarifname" />
                            <Column
                                field="fPreis"
                                header="Preis"
                                body={(rowData: any) => (
                                    <>
                                        {rowData.fPreis && (
                                            <>{rowData.fPreis.toFixed(2)} €</>
                                        )}
                                    </>
                                )}
                            />
                            <Column
                                field="nLaufzeitInMonaten"
                                header="Laufzeit"
                            />
                        </DataTable>
                    </Fieldset>
                </div>
            </div>
        </div>
    );
};

export default FullUserData;
