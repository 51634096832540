import { DefaultEventsMap } from "@socket.io/component-emitter";
import { nodeAxios } from "logik/nodeApiCalls";
import { useEffect } from "react";
import socketIO, { Socket } from "socket.io-client";

const url: any = process.env.REACT_APP_API_SERVER_DOMAIN_NODE;
export const useSocket = (on: string, cb: (data: any) => void) => {
    useEffect(() => {
        let socket: Socket<DefaultEventsMap, DefaultEventsMap>;
        setTimeout(() => {
            socket = socketIO(url, {
                query: {
                    token: nodeAxios.defaults.headers.Authorization,
                },
            });

            socket.on(on, (data) => {
                cb(data);
            });
        }, 2000);

        return () => {
            socket && socket.disconnect();
        };
    }, []);
};
