import firebase from "firebase/compat";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuCategory } from "./Layout";
import SidebarLink from "./SidebarLink";

type Props = {
    stdmenu: MenuCategory;
};

const Sidebar = ({ stdmenu }: Props) => {
    const [sideBarSearch, setSideBarSearch] = useState<string>("");
    const [filterdMenu, setFilterdMenu] = useState<
        {
            title: string;
            icon: string;
            to: string;
        }[]
    >([]);

    const onSideBarSearch = (searchValue: string) => {
        const _menu = [...stdmenu.menuItems];
        const _filterdMenu = _menu.filter((item) =>
            item.title
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase())
        );
        setFilterdMenu(_filterdMenu);
    };

    useEffect(() => {
        if (!filterdMenu.length) {
            setFilterdMenu(stdmenu.menuItems);
        }
    }, [stdmenu]);

    return (
        <div
            className="app-sidebar surface-card hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none shadow-2"
            style={{ width: "290px", borderRadius: "12px" }}
        >
            <div className="flex flex-column h-full">
                <ScrollPanel
                    style={{
                        width: "100%",
                        height: "calc(100vh - 297px)",
                    }}
                >
                    <div className="p-3">
                        <div className="p-input-icon-left w-full">
                            <i className="pi pi-search"></i>
                            <InputText
                                value={sideBarSearch}
                                onChange={(e) => {
                                    onSideBarSearch(e.target.value);
                                    setSideBarSearch(e.target.value);
                                }}
                                className="border-1 surface-border w-full"
                                placeholder="Search"
                                style={{ borderRadius: "30px" }}
                            />
                        </div>
                    </div>

                    <ul className="list-none px-3 pb-3 pt-0 m-0">
                        <li>
                            <div className="p-3 flex align-items-center justify-content-between text-600">
                                <span className="font-medium text-sm text-900">
                                    {stdmenu.name}
                                </span>
                            </div>
                            <ul className="list-none p-0 m-0 overflow-hidden">
                                {filterdMenu.map((item, index) => {
                                    return (
                                        <SidebarLink
                                            onClick={undefined}
                                            key={index}
                                            to={item.to}
                                            icon={item.icon}
                                            title={item.title}
                                            classNames="sidebar-nav main"
                                        />
                                    );
                                })}
                            </ul>
                        </li>
                    </ul>
                </ScrollPanel>

                <div className="mt-auto border-top-1 surface-border p-3 flex justify-content-between">
                    <a
                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center border-2 surface-border hover:surface-100 text-600 transition-colors transition-duration-150 border-circle"
                        style={{ width: "40px", height: "40px" }}
                    >
                        <i className="pi pi-file text-xl"></i>
                    </a>
                    <a
                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center border-2 surface-border hover:surface-100 text-600 transition-colors transition-duration-150 border-circle"
                        style={{ width: "40px", height: "40px" }}
                    >
                        <i className="pi pi-star text-xl"></i>
                    </a>
                    <a
                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center border-2 surface-border hover:surface-100 text-600 transition-colors transition-duration-150 border-circle"
                        style={{ width: "40px", height: "40px" }}
                    >
                        <i className="pi pi-cog text-xl"></i>
                    </a>
                    <a
                        onClick={() => firebase.auth().signOut()}
                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center bg-cyan-500 text-white border-2 border-cyan-600 hover:bg-cyan-600 text-600 transition-colors transition-duration-150 border-circle"
                        style={{ width: "40px", height: "40px" }}
                    >
                        <i className="pi pi-sign-out text-xl"></i>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
