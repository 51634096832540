import { certCount } from "logik/nodeApiCalls";
import React, { useEffect, useState } from "react";
import CertificateCounter from "./CertificateCounter";

const CertificateCounterContainer = () => {
    const [certs, setCerts] = useState<any[]>([]);

    useEffect(() => {
        getCerts();
    }, []);

    const getCerts = async () => {
        let _certs = await certCount();
        console.log(_certs);
        setCerts(_certs);
    };
    return (
        <>
            <CertificateCounter
                cert={
                    certs.length > 0
                        ? certs[0]
                        : { cAlias: "Loading..", nAnzahl: 0 }
                }
            />
            <CertificateCounter
                cert={
                    certs.length > 1
                        ? certs[1]
                        : { cAlias: "Loading..", nAnzahl: 0 }
                }
            />
            <CertificateCounter
                cert={
                    certs.length > 2
                        ? certs[2]
                        : { cAlias: "Loading..", nAnzahl: 0 }
                }
            />
            <CertificateCounter
                cert={
                    certs.length > 3
                        ? certs[3]
                        : { cAlias: "Loading..", nAnzahl: 0 }
                }
            />
        </>
    );
};

export default CertificateCounterContainer;
