import { proxChangeVmSpec } from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

type Props = {
    id: number;
    cpu: number;
    visible: boolean;
    onHide: () => void;
};

const ServerChangeCpuDialog = ({ id, cpu, visible, onHide }: Props) => {
    const [newCpu, setNewCpu] = useState<number>(cpu);

    const queryClient = useQueryClient();
    const changeSpec = useMutation(
        (values: { type: string; value: number; reboot: boolean }) =>
            proxChangeVmSpec(id, values.type, values.value),
        {
            onSuccess: async () => {
                queryClient.invalidateQueries(["server", id]);
            },
        }
    );

    const dialogFooter = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        onHide();
                    }}
                    label="Abbrechen"
                    icon="pi pi-times"
                    className="p-button-text"
                />
                <Button
                    disabled={cpu === newCpu}
                    label="Übernehmen"
                    onClick={() => {
                        console.log("params", {
                            type: "cores",
                            value: newCpu % 2 === 0 ? newCpu / 2 : newCpu,
                            reboot: false,
                        });
                        changeSpec.mutate({
                            type: "cores",
                            value: newCpu,
                            reboot: false,
                        });
                        onHide();
                    }}
                    icon="pi pi-check"
                    autoFocus
                />
            </div>
        );
    };

    return (
        <Dialog
            header="CPU Ändern"
            visible={visible}
            onHide={onHide}
            style={{ width: "450px" }}
            footer={dialogFooter}
        >
            <InputNumber
                className="w-full"
                value={newCpu}
                onChange={(e) => setNewCpu(e.value)}
                min={1}
                max={32}
                suffix=" Kerne"
            />
            <Slider
                value={newCpu}
                onChange={(e) => setNewCpu(parseInt(e.value.toString()))}
                step={1}
                min={1}
                max={32}
            />
        </Dialog>
    );
};

export default ServerChangeCpuDialog;
