import { Dialog } from "primereact/dialog";
import { NavLink } from "react-router-dom";
import { MenuCategory } from "./Layout";
import SidebarLink from "./SidebarLink";

type Props = {
    visible: boolean;
    hide: () => void;
    adminMenu: MenuCategory;
    viewMenu: MenuCategory;
    migrationMenu: MenuCategory;
};

const AdminSidebar = ({
    visible,
    hide,
    adminMenu,
    viewMenu,
    migrationMenu,
}: Props) => {
    return (
        <Dialog
            draggable={false}
            className="app-sidebar"
            header="Adminmenu"
            visible={visible}
            onHide={hide}
            style={{ height: "100vh", minWidth: "300px" }}
            position="right"
        >
            {adminMenu.menuItems.length > 0 && (
                <ul className="list-none p-3 m-0">
                    <li>
                        <div className="p-3 flex align-items-center justify-content-between text-600">
                            <span className="font-medium text-sm text-900">
                                {adminMenu.name}
                            </span>
                        </div>
                        <ul className="list-none p-0 m-0 overflow-hidden">
                            {adminMenu.menuItems.map((item, index) => {
                                return (
                                    <SidebarLink
                                        onClick={hide}
                                        key={index}
                                        to={item.to}
                                        icon={item.icon}
                                        title={item.title}
                                        classNames="sidebar-nav admin"
                                    />
                                );
                            })}
                        </ul>
                    </li>
                </ul>
            )}
            {viewMenu.menuItems.length > 0 && (
                <ul className="list-none p-3 m-0">
                    <li>
                        <div className="p-3 flex align-items-center justify-content-between text-600">
                            <span className="font-medium text-sm text-900">
                                {viewMenu.name}
                            </span>
                        </div>
                        <ul className="list-none p-0 m-0 overflow-hidden">
                            {viewMenu.menuItems.map((item, index) => {
                                return (
                                    <SidebarLink
                                        onClick={hide}
                                        key={index}
                                        to={item.to}
                                        icon={item.icon}
                                        title={item.title}
                                        classNames="sidebar-nav admin"
                                    />
                                );
                            })}
                        </ul>
                    </li>
                </ul>
            )}
            {migrationMenu.menuItems.length > 0 && (
                <ul className="list-none p-3 m-0">
                    <li>
                        <div className="p-3 flex align-items-center justify-content-between text-600">
                            <span className="font-medium text-sm text-900">
                                {migrationMenu.name}
                            </span>
                        </div>
                        <ul className="list-none p-0 m-0 overflow-hidden">
                            {migrationMenu.menuItems.map((item, index) => {
                                return (
                                    <SidebarLink
                                        onClick={hide}
                                        key={index}
                                        to={item.to}
                                        icon={item.icon}
                                        title={item.title}
                                        classNames="sidebar-nav admin"
                                    />
                                );
                            })}
                        </ul>
                    </li>
                </ul>
            )}
        </Dialog>
    );
};

export default AdminSidebar;
