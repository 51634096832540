import React, { Component } from "react";
import { Card } from "primereact/card";
import { motion } from "framer-motion";
import DomainChecker from "../domain/DomainChecker";
import RightChecker from "../shared/RightChecker";
import Feature from "./Feature";
import Statistics from "./Statistics";
import Hero from "./Hero";
import "./dashboard.css";
export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <motion.div exit={{ opacity: 0 }}>
                <Statistics></Statistics>
                <Card className="dash_banner mb-2">
                    <Hero />
                </Card>

                {/*  <RightChecker right="19">
                    <Card className="dash_banner"

                    >
                        <DomainChecker />
                    </Card>
                </RightChecker> */}

                <Card className="dash_banner">
                    <Feature />
                </Card>
            </motion.div>
        );
    }
}
