import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import CertificateCounter from "./CertificateCounter";
import CertificateVisual from "./CertificateVisual";
import YearlyCertificate from "./YearlyCertificate";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
import DeleteButtonCerts from "./DeleteButtonCerts";
import {
    adminPoolList,
    adminShowCert,
    apiCancelCerts,
    withDrawCerts,
} from "logik/nodeApiCalls";
import CertificateCounterContainer from "./CertificateCounterContainer";
import ConfirmButton from "../../shared/ConfirmButton";

export interface CertInterface {
    kCertificate: number;
    cCertDomain: string;
    bGekuendigt: null | boolean;
    nId: number;
    fPreis: number;
    dErstellt: string;
    dGültig: string;
    cStatus: string;
    cAlfa: boolean;
    cType: string;
    fkPool: string;
    KDN: string;
    cFirma: string;
}

export interface PoolInterface {
    kcPoolName: string;
}

type Props = {};

type State = {
    abos: CertInterface[];
    pools: PoolInterface[];
    time: any;
    // globalFilterValue1: string;
    //   filters1: null,
};

export default class ShowDomains extends Component<Props, State> {
    state = {
        abos: [],
        pools: [{ kcPoolName: "moso" }],
        time: DateTime.now().plus({ days: -30 }),
        // globalFilterValue1: "",
        // filters1: null
    };

    constructor(props: Props) {
        super(props);

        this.showDomainList = this.showDomainList.bind(this);
        this.poolList = this.poolList.bind(this);
    }

    componentDidMount() {
        this.showDomainList();
    }

    // onGlobalFilterChange1(e: []) {
    //   const value = e.target.value;
    //   let filters1 = {this.state.filters1};
    //   filters1["global"].value = value;

    //   this.setState({ filters1, globalFilterValue1: value });
    // }
    // <CertificateCounter />
    render() {
        return (
            <div>
                <div className="grid">
                    <CertificateCounterContainer />
                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <YearlyCertificate />
                                <div
                                    className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                    style={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                    }}
                                >
                                    <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DataTable
                    stripedRows
                    value={this.state.abos}
                    responsiveLayout="scroll"
                    paginator
                    rows={15}
                    rowsPerPageOptions={[15, 30, 45, 60, 75, 90]}
                    filterDisplay="row"
                    sortField="dGültig"
                    sortOrder={1}
                >
                    <Column
                        field="cCertDomain"
                        header="Zertifkat"
                        sortable
                        showFilterMatchModes={true}
                        filter
                    />
                    <Column field="nId" header="ID" sortable filter />
                    <Column
                        field="dErstellt"
                        header="Erstellt am"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Tag
                                severity="info"
                                value={DateTime.fromISO(rowdata.dErstellt, {
                                    zone: "utc",
                                })
                                    .toFormat("dd/MM/yyyy")
                                    .toString()}
                            />
                        )}
                    />
                    <Column
                        field="fPreis"
                        header="Preis"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Tag value={rowdata.fPreis.toFixed(2) + " €"}></Tag>
                        )}
                    />
                    <Column
                        field="dGültig"
                        header="Gültig bis"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Tag
                                severity={
                                    DateTime.fromISO(rowdata.dErstellt, {
                                        zone: "utc",
                                    })
                                        .plus({ years: 1 })
                                        .minus({ days: 1 }) < DateTime.now()
                                        ? "danger"
                                        : "success"
                                }
                                value={DateTime.fromISO(rowdata.dErstellt, {
                                    zone: "utc",
                                })
                                    .plus({ years: 1 })
                                    .minus({ days: 1 })
                                    .toFormat("dd/MM/yyyy")
                                    .toString()}
                            />
                        )}
                    />
                    <Column
                        field="cStatus"
                        header="Aktiv?"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Tag
                                severity={
                                    rowdata.cStatus === "Aktiv"
                                        ? "success"
                                        : "danger"
                                }
                                value={rowdata.cStatus}
                            ></Tag>
                        )}
                    />
                    <Column
                        field="cAlfa"
                        header="Alfahosting?"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Tag
                                severity="info"
                                icon={
                                    rowdata.cAlfa.valueOf() === true
                                        ? "pi pi-checked"
                                        : "pi pi-times"
                                }
                            ></Tag>
                        )}
                    />
                    <Column
                        field="cType"
                        header="Zertifikatstyp"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Tag value={rowdata.cType}></Tag>
                        )}
                    />
                    <Column
                        field="fkPool"
                        header="Pool"
                        sortable
                        showFilterMatchModes={true}
                        filter
                    />
                    <Column
                        field="KDN"
                        header="KDN"
                        sortable
                        filter
                        body={(rowdata: CertInterface) => (
                            <Button
                                className="p-button-text"
                                label={"" + rowdata.KDN}
                                tooltip={rowdata.cFirma}
                            ></Button>
                        )}
                    />
                    <Column
                        body={(rowdata: CertInterface) => {
                            if (rowdata.bGekuendigt !== null) {
                                return (
                                    <ConfirmButton
                                        btnLabel="Wiederrufen"
                                        btnIcon="pi pi-replay"
                                        btnClassNames="w-full"
                                        confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen?"
                                        confirmHeader="Bestätigung"
                                        confirmAccept={() =>
                                            this.withDrawCertHandler(
                                                rowdata.kCertificate
                                            )
                                        }
                                    />
                                );
                            }
                            return (
                                <ConfirmButton
                                    btnLabel="Kündigen"
                                    btnIcon="pi pi-times"
                                    btnClassNames="p-button-danger w-full"
                                    confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                    confirmHeader="Bestätigung"
                                    confirmAccept={() =>
                                        this.deleteCertHandler(
                                            rowdata.kCertificate
                                        )
                                    }
                                />
                            );
                        }}
                    />
                </DataTable>
            </div>
        );
    }

    async showDomainList() {
        let abos = await adminShowCert();
        console.log(abos);
        this.setState({ abos: abos });
        console.log(abos);
    }

    async deleteCertHandler(kCertificate: number) {
        await apiCancelCerts(kCertificate);
        this.showDomainList();
    }

    async withDrawCertHandler(kCertificate: number) {
        await withDrawCerts(kCertificate);
        this.showDomainList();
    }

    async poolList() {
        let pools = await adminPoolList();
        this.setState({ pools: pools });
    }
}
