import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import Protection2FA from "components/shared/Protection2FA";
import {
    proxDownloadFilesForFileRestore,
    proxGetFilesForFileRestore,
} from "logik/nodeApiCalls";
export default class FileRestore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: null,
            loading: true,
            buttonloading: false,
            currentpath: [],
        };

        this.loadOnExpand = this.loadOnExpand.bind(this);
        this.getRootFiles = this.getRootFiles.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.nodeTemplate = this.nodeTemplate.bind(this);
    }

    render() {
        return (
            <Dialog
                onShow={this.getRootFiles}
                visible={this.props.time !== null}
                onHide={this.props.onHide}
                header="File-Restore"
                style={{ maxWidth: "94vw" }}
            >
                <Protection2FA minutesOld={8}>
                    <TreeTable
                        value={this.state.nodes}
                        onExpand={this.loadOnExpand}
                        loading={this.state.loading}
                        nodeTemplate={this.nodeTemplate}
                        key="filepath"
                    >
                        <Column
                            body={(node) => <b>{node.text}</b>}
                            header="Name"
                            expander
                        ></Column>
                        <Column
                            body={(node) => {
                                var size = "-";
                                if ("size" in node) {
                                    if (node.size / 1000000 > 1000) {
                                        size =
                                            (node.size / 1000000000).toFixed(
                                                2
                                            ) + " GB";
                                    } else if (node.size / 1000 > 1000) {
                                        size =
                                            (node.size / 1000000).toFixed(2) +
                                            " MB";
                                    } else if (node.size > 1000) {
                                        size =
                                            (node.size / 1000).toFixed(2) +
                                            " KB";
                                    } else {
                                        size = node.size + " B";
                                    }
                                }
                                return size;
                            }}
                            field="size"
                            header="Size"
                        ></Column>
                        <Column
                            body={(node) => (
                                <Button
                                    onClick={() =>
                                        this.downloadFile(
                                            node.filepath,
                                            node.text,
                                            node.leaf
                                        )
                                    }
                                    icon="pi pi-download"
                                    className="p-button-rounded p-button-secondary mr-5"
                                    loading={this.state.currentpath.includes(
                                        node.filepath
                                    )}
                                    label={(() => {
                                        if (
                                            this.state.currentpath.includes(
                                                node.filepath
                                            )
                                        ) {
                                            return "Der Download wird vorbereitet";
                                        } else {
                                            return "";
                                        }
                                    })()}
                                />
                            )}
                            header="Download"
                        ></Column>
                    </TreeTable>
                </Protection2FA>
            </Dialog>
        );
    }

    nodeTemplate(node, options) {
        let label = <b>{node.text}</b>;

        return (
            <span className={options.className + " "}>
                {label}

                <span>{node.size}</span>
            </span>
        );
    }

    async getRootFiles() {
        this.setState({
            loading: true,
        });
        var results = await proxGetFilesForFileRestore(
            this.props.vmid,
            this.props.time,
            "/"
        );
        console.log("hhalalalalal", results);

        if (results) {
            results = results.map((e) => {
                if (e.leaf === 0) {
                    return {
                        ...e,
                        leaf: false,
                        key: e.filepath,
                    };
                } else {
                    return {
                        ...e,
                        leaf: true,
                        key: e.filepath,
                    };
                }
            });

            this.setState({
                nodes: results,
                loading: false,
            });
            console.log(results);
        }
    }

    async downloadFile(path, name, leaf) {
        var oldcurrentpath = [...this.state.currentpath];

        oldcurrentpath.push(path);

        this.setState({
            currentpath: oldcurrentpath,
        });

        var result = await proxDownloadFilesForFileRestore(
            this.props.vmid,
            this.props.time,
            path,
            leaf
        );
        if (leaf) {
            const temp = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement("a");
            link.href = temp;
            link.setAttribute("target", "_blank");
            link.download = name;

            document.body.appendChild(link);
            link.click();
            // link.remove();

            oldcurrentpath = [];

            this.setState({
                currentpath: oldcurrentpath,
            });
        } else {
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.setAttribute("type", "hidden");
            link.href = "data:application/zip;base64," + result;
            link.download = name + ".zip";
            link.click();
            document.body.removeChild(link);
        }
    }

    async loadOnExpand(event) {
        if (!event.node.children) {
            this.setState({
                loading: true,
            });

            var results = await proxGetFilesForFileRestore(
                this.props.vmid,
                this.props.time,
                event.node.filepath
            );

            let node = { ...event.node };
            node.children = [];
            if (results) {
                node.children = results.map((e) => {
                    if (e.leaf === 0) {
                        return {
                            ...e,
                            leaf: false,
                            key: e.filepath,
                        };
                    } else {
                        return {
                            ...e,
                            leaf: true,
                            key: e.filepath,
                        };
                    }
                });

                var newNodes = [...this.state.nodes];

                this.updateNodeInTree(
                    newNodes[0],
                    event.node.filepath,
                    node.children
                );

                this.setState({
                    nodes: newNodes,
                    loading: false,
                });
            }
        }
    }

    updateNodeInTree(node, filepath, children) {
        if (node.filepath === filepath) {
            node.children = children;
        } else if (node.children != null) {
            for (let i = 0; i < node.children.length; i++) {
                this.updateNodeInTree(node.children[i], filepath, children);
            }
        }
    }
}
