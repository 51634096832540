import RightChecker from "components/shared/RightChecker";
import { proxCreateSnapshotAdmin, proxGetTarifForVm } from "logik/nodeApiCalls";
import { parseMinutePriceToMonthPrice } from "logik/utilities";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import { FaMemory } from "react-icons/fa";
import { FiCpu } from "react-icons/fi";
import { GiSpeedometer } from "react-icons/gi";
import { ImDrive } from "react-icons/im";

interface Props {
    vmid: string;
}
interface State {
    tarif: TarifDetails | undefined;
}
export interface TarifDetails {
    kTarif?: number;
    sName: string;
    sMinRuntime: number;
    sBeschreibung: string;
    sPreis: number;
    sGbRam: number;
    sCpu: number;
    sSpeicherNP: number;
    sSpeicherHP: number;
    fName: string;
    fBeschreibung: number;
    fRamPrice: number;
    fCpuPrice: number;
    fSpeicherHighPerformancePrice: number;
    fSpeicherNormalPerformancePrice: number;
    [key: string]: any;
}
export default class ServerTarifDetails extends Component<Props, State> {
    state: State = {
        tarif: undefined,
    };
    toast: Toast | null;
    constructor(props: Props) {
        super(props);
        this.toast = null;
        this.getTarif = this.getTarif.bind(this);
    }

    componentDidMount() {
        this.getTarif();
    }

    render() {
        if (this.state.tarif !== undefined) {
            console.log(this.state.tarif.sName);
        }
        return (
            <RightChecker right="21">
                <Toast ref={(el) => (this.toast = el)} />
                {this.state.tarif === undefined ? (
                    <div />
                ) : (
                    <div>
                        <RightChecker right="10000">
                            <Divider>Admin</Divider>
                            <Button
                                label="Snapshot erstellen (NUR FÜR ADMIN SICHTBAR)"
                                onClick={() => {
                                    proxCreateSnapshotAdmin(this.props.vmid);
                                    if (this.toast) {
                                        this.toast.show({
                                            severity: "success",
                                            summary: "Erfolg",
                                            detail: "Snapshot wurde erstellt",
                                            life: 3000,
                                        });
                                    }
                                }}
                                tooltip="Erstellt einen manuellen Snapshot (passiert bei änderungen über dieses Portal automatisch) !!! NUR BENUTZEN WENN MANUELLE ÄNDERUNGEN ÜBER PROXMOX GEMACHT WURDEN!!!"
                            />
                            <Divider />
                        </RightChecker>
                        <div className="grid">
                            {this.state.tarif.kTarif !== null && (
                                <div className="col-12 lg:col-6 p-lg-6">
                                    <div className="surface-0 p-4 shadow-2 border-round h-full">
                                        <div className="p-card-header p-text-center">
                                            <h2>{this.state.tarif.sName}</h2>
                                        </div>
                                        <div>
                                            {this.state.tarif.sBeschreibung}
                                        </div>
                                        <div className="p-card-body p-text-center">
                                            <h1 className="p-card-title pricing-card-title">
                                                {this.state.tarif.sPreis}
                                                <small className="text-muted fw-light">
                                                    /mo
                                                </small>
                                            </h1>
                                            <ul
                                                className="list-group mt-3 mb-4 text-left"
                                                style={{ listStyle: "none" }}
                                            >
                                                <li className="list-group-item">
                                                    <FaMemory className="mr-1" />
                                                    {this.state.tarif.sGbRam}GB
                                                    vRam
                                                </li>
                                                <li className="list-group-item">
                                                    <FiCpu className="mr-1" />
                                                    {this.state.tarif.sCpu}{" "}
                                                    Kerne
                                                </li>
                                                <li className="list-group-item">
                                                    <ImDrive className="mr-1" />
                                                    {
                                                        this.state.tarif
                                                            .sSpeicherNP
                                                    }
                                                    GB Normal Performance
                                                    Storage
                                                </li>
                                                <li className="list-group-item">
                                                    <ImDrive className="mr-1" />
                                                    {
                                                        this.state.tarif
                                                            .sSpeicherHP
                                                    }
                                                    GB High Performance Storage
                                                </li>
                                                <li className="list-group-item">
                                                    <GiSpeedometer className="mr-1" />
                                                    Unlimited Traffic
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="col-12 lg:col-6 p-lg-6">
                                <div className="surface-0 p-4 shadow-2 border-round h-full">
                                    <div className="p-card-header p-text-center">
                                        {this.state.tarif.kTarif === null ? (
                                            <h2>Flex Tarif</h2>
                                        ) : (
                                            <h2>Zusätzliche Ressourcen</h2>
                                        )}
                                        {this.state.tarif.fName}
                                    </div>
                                    <div>{this.state.tarif.fBeschreibung}</div>
                                    <div className="p-card-body p-text-center">
                                        <ul
                                            className="list-group mt-3 mb-4 text-left"
                                            style={{ listStyle: "none" }}
                                        >
                                            <li className="list-group-item">
                                                <FaMemory className="mr-1" />
                                                {parseMinutePriceToMonthPrice(
                                                    this.state.tarif.fRamPrice
                                                )}{" "}
                                                € pro GB vRam pro Monat
                                            </li>
                                            <li className="list-group-item">
                                                <FiCpu className="mr-1" />
                                                {parseMinutePriceToMonthPrice(
                                                    this.state.tarif.fCpuPrice
                                                )}{" "}
                                                € pro Kerne pro Monat
                                            </li>
                                            <li className="list-group-item">
                                                <ImDrive className="mr-1" />
                                                {parseMinutePriceToMonthPrice(
                                                    this.state.tarif
                                                        .fSpeicherNormalPerformancePrice
                                                )}
                                                € pro GB pro Monat Normal
                                                Performance Storage
                                            </li>
                                            <li className="list-group-item">
                                                <ImDrive className="mr-1" />
                                                {parseMinutePriceToMonthPrice(
                                                    this.state.tarif
                                                        .fSpeicherHighPerformancePrice
                                                )}
                                                € pro GB pro Monat High
                                                Performance Storage
                                            </li>
                                            <li className="list-group-item">
                                                <GiSpeedometer className="mr-1" />
                                                Unlimited Traffic
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </RightChecker>
        );
    }

    async getTarif() {
        var tarif: TarifDetails = await proxGetTarifForVm(this.props.vmid);
        this.setState({ tarif: tarif[0] });
    }
}
