import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import CertificateCounter from "./CertificateCounter";
import CertificateVisual from "./CertificateVisual";
import YearlyCertificate from "./YearlyCertificate";
import { Button } from "primereact/button";
import DeleteButtonTar from "./DeleteButtonTar";
import {
    adminShowTariff,
    apiCancelTarif,
    apiwithDrawTarif,
    updateVmNames,
} from "logik/nodeApiCalls";
import { DateTime } from "luxon";
import { OverlayPanel } from "primereact/overlaypanel";
import StandardTarifDetailsOverlay from "./TarifDetailsOverlay";
import SetKampSnapshot from "./SetKampSnapshot";
import SetSPLA from "./SetSPA";
import ConfirmButton from "../../shared/ConfirmButton";

export interface TarifInterface {
    kVim: number;
    cVMname: string;
    fkcPool: string;
    cVmid: string;
    cNodeName: string;
    dCreated: string;
    fkTarif: number | null;
    fkFlexTarif: number;
    fkTarifFromReseller: null;
    fkFlexTarifFromReseller: null;
    bGekuendigt: boolean | null;
    dAboBeendetAm: string;
    pool: Pool;
    cIP: string | null;
    tarif_flextarif_tarif_flextarifTovim_fkFlexTarif: TarifFlextarifTarifFlextarifTovimFkFlexTarif;
    tarif_standardtarif_tarif_standardtarifTovim_fkTarif: TarifStandardtarifTarifStandardtarifTovimFkTarif | null;
}

export interface Pool {
    kcPoolName: string;
    fkUser: number;
    qnexxtUser: QnexxtUser;
}

export interface QnexxtUser {
    cKundennummer: null | string;
    cFirma: null | string;
}

export interface TarifFlextarifTarifFlextarifTovimFkFlexTarif {
    kFlexTarif: number;
    cName: string;
    cBeschreibung: string;
    fRamPrice: number;
    fCpuPrice: number;
    fSpeicherHighPerformancePrice: number;
    fSpeicherNormalPerformancePrice: number;
    bDefault: boolean;
    fkUserReseller: null;
    dErstellt: string;
}

export interface TarifStandardtarifTarifStandardtarifTovimFkTarif {
    kTarif: number;
    cName: string;
    cBeschreibung: string;
    nMinMonthRuntime: number;
    fPrice: number;
    nGbRam: number;
    nCpu: number;
    nSpeicherNormalPerformance: number;
    nSpeicherHighPerformance: number;
    cVmidOfTemplate: string;
    bDefault: boolean;
    bArchiviert: boolean;
    fkUserReseller: null;
    dErstellt: string;
}

export interface PoolInterface {
    kcPoolName: string;
}

interface Props {}

const ShowUserTariff = (props: Props) => {
    const [tariffs, setTariffs] = useState<TarifInterface[]>([]);

    const showTariffList = async () => {
        let tarife = await adminShowTariff();
        console.log(tarife);
        setTariffs(tarife);
    };

    const calculateTotalVimPrice = () => {
        let price = 0;
        tariffs.forEach((tariff) => {
            if (tariff.tarif_standardtarif_tarif_standardtarifTovim_fkTarif)
                price +=
                    tariff.tarif_standardtarif_tarif_standardtarifTovim_fkTarif
                        .fPrice;
        });

        return <>{price.toFixed(2)}</>;
    };

    const deleteUserTarifHandler = async (kVim: number) => {
        await apiCancelTarif(kVim);
        await showTariffList();
    };

    const withDrawUserTarifHandler = async (kVim: number) => {
        await apiwithDrawTarif(kVim);
        await showTariffList();
    };

    useEffect(() => {
        showTariffList();
    }, []);

    return (
        <div>
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">
                                    Anzahl Vims
                                </span>
                                <div className="text-900 font-medium text-xl">
                                    {tariffs.length}
                                </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">
                                    Montalich
                                </span>
                                <div className="text-900 font-medium text-xl">
                                    {calculateTotalVimPrice()} €
                                </div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                }}
                            >
                                <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                stripedRows
                value={tariffs}
                responsiveLayout="scroll"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                sortField="cNutzer"
                filterDisplay="row"
                sortOrder={1}
            >
                <Column
                    field="cVMname"
                    header="VM-Name"
                    sortable
                    filter
                    body={(rowdata: TarifInterface) => (
                        <SetSPLA
                            kcPoolName={rowdata.fkcPool}
                            name={rowdata.cVMname}
                        />
                    )}
                ></Column>
                <Column
                    field="cVmid"
                    header="VM-ID"
                    sortable
                    filter
                    body={(rowdata: TarifInterface) =>
                        rowdata.cVmid == "KAMP" ? (
                            <SetKampSnapshot kVim={rowdata.kVim} />
                        ) : (
                            rowdata.cVmid
                        )
                    }
                ></Column>
                <Column
                    field="dErstellt"
                    header="Erstellt am"
                    sortable
                    filter
                    body={(rowdata: TarifInterface) => (
                        <Tag
                            severity="info"
                            value={DateTime.fromISO(rowdata.dCreated).toFormat(
                                "dd.MM.yyyy"
                            )}
                        ></Tag>
                    )}
                ></Column>
                <Column
                    field="tarif_standardtarif_tarif_standardtarifTovim_fkTarif.cName"
                    header="Tarif"
                    body={(rowdata: TarifInterface) => (
                        <>
                            <StandardTarifDetailsOverlay
                                tarif={
                                    rowdata.tarif_standardtarif_tarif_standardtarifTovim_fkTarif
                                }
                                flexTarif={
                                    rowdata.tarif_flextarif_tarif_flextarifTovim_fkFlexTarif
                                }
                            />
                        </>
                    )}
                    sortable
                    showFilterMatchModes={true}
                    filter
                ></Column>
                <Column
                    field="fPreis"
                    header="Preis"
                    sortable
                    filter
                    body={(rowdata: TarifInterface) => (
                        <Tag
                            value={
                                rowdata
                                    .tarif_standardtarif_tarif_standardtarifTovim_fkTarif
                                    ?.fPrice + " €"
                            }
                        ></Tag>
                    )}
                ></Column>
                <Column
                    field="pool.kcPoolName"
                    header="Pool"
                    sortable
                    filter
                ></Column>
                <Column
                    field="KDN"
                    header="KDN"
                    sortable
                    filter
                    body={(rowdata: TarifInterface) => (
                        <Button
                            className="p-button-text"
                            label={
                                rowdata.pool.qnexxtUser.cKundennummer
                                    ? rowdata.pool.qnexxtUser.cKundennummer
                                    : "Keine KDN"
                            }
                            tooltip={"" + rowdata.pool.qnexxtUser.cFirma}
                        ></Button>
                    )}
                />
                <Column field="cIP" header="IP" sortable filter></Column>

                <Column
                    body={(rowdata: TarifInterface) => {
                        if (rowdata.bGekuendigt) {
                            return (
                                <ConfirmButton
                                    btnLabel="Wiederrufen"
                                    btnIcon="pi pi-replay"
                                    btnClassNames="w-full"
                                    confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen?"
                                    confirmHeader="Bestätigung"
                                    confirmAccept={() =>
                                        withDrawUserTarifHandler(rowdata.kVim)
                                    }
                                />
                            );
                        }
                        return (
                            <ConfirmButton
                                btnLabel="Kündigen"
                                btnIcon="pi pi-times"
                                btnClassNames="p-button-danger w-full"
                                confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                confirmHeader="Bestätigung"
                                confirmAccept={() =>
                                    deleteUserTarifHandler(rowdata.kVim)
                                }
                            />
                        );
                    }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default ShowUserTariff;
