import React, { useEffect, useRef, useState } from "react";
import Loading from "components/shared/Loading";
import RightChecker from "components/shared/RightChecker";
import {
    proxChangeVmSpec,
    proxGetVmInfo,
    proxServerAction,
} from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import { Knob } from "primereact/knob";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { FaMemory, FaWindows, FaUbuntu } from "react-icons/fa";
import { GrSystem } from "react-icons/gr";
import { FiCpu } from "react-icons/fi";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router";
import LaufwerkList from "./LaufwerkList";
import ServerBackups from "./ServerBackups";
import ServerRessourceUssage from "./ServerRessourceUssage";
import ServerTarifDetails from "./ServerTarifDetails";
import ServerVerlauf from "./ServerVerlauf";
import "../shared/Server.css";
import ServerChangeRam from "./ServerChangeRam";
import ServerChangeRamDialog from "./ServerChangeRamDialog";
import ServerChangeCpuDialog from "./ServerChangeCpuDialog";
import ServerDiskList from "./ServerDiskList";
import { AiFillTablet } from "react-icons/ai";
import {
    BiDollarCircle,
    BiHistory,
    BiRedo,
    BiServer,
    BiTachometer,
} from "react-icons/bi";
import { useRefreshVm } from "hooks/useRefreshVms";

const ServerDetailPage = () => {
    let params = useParams<{ id: string }>();
    const queryClient = useQueryClient();
    const toastRef = useRef<Toast>(null);

    const [changeRamDialogVisibible, setChangeRamDialogVisible] =
        useState<boolean>(false);
    const [changeCpuDialogVisibible, setChangeCpuDialogVisible] =
        useState<boolean>(false);

    const [serverStatusChange, setServerStatusChange] = useState<{
        old: string;
        isChangeing: boolean;
    }>({ old: "", isChangeing: false });

    const { data, error, isLoading, isFetching, refetch } = useQuery(
        ["server", params.id],
        async () => await proxGetVmInfo(params.id)
    );

    useRefreshVm(params.id, () => {
        refetch();
        console.log("jahhhuuuu TAsk für die vm ist fertig");
    });

    const serveractions = useMutation(
        (values: { type: "stop" | "reboot" | "start" }) =>
            proxServerAction(params.id, values.type),
        {
            onMutate: async (values) => {
                toastRef.current?.show({
                    severity: "success",
                    summary:
                        values.type === "stop"
                            ? "Server gestoppt"
                            : values.type === "start"
                            ? "Server Startet"
                            : "Server Neustart",
                    detail:
                        values.type === "stop"
                            ? "Der Server wird heruntergefahren."
                            : values.type === "start"
                            ? "Der Server wird gestartet."
                            : "Der Server wird neu gestartet.",
                    life: 3000,
                });
                await queryClient.cancelQueries(["todos"]);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(["server", params.id]);
                console.log(serverStatusChange);
                console.log({
                    ...serverStatusChange,
                    ...{ isChangeing: true },
                });
            },
        }
    );

    useEffect(() => {
        if (data) {
            console.log(data);
            if (
                serverStatusChange.old === "" ||
                data.vmstatus.status !== serverStatusChange.old
            ) {
                setServerStatusChange({
                    old: data.vmstatus.status,
                    isChangeing: false,
                });
            }
        }
    }, [data]);

    if (isLoading) {
        return (
            <div className="flex justify-content-center">
                <Loading size="20vw" />
            </div>
        );
    }

    if (error) {
        return <>Error while fetching Server...</>;
    }
    return (
        <div>
            <div
                className="text-primary flex align-items-center p-3"
                style={{
                    minHeight: "50px",
                    borderBottom: "1px solid #dee2e6",
                }}
            >
                <Toast ref={toastRef} />
                {false ? (
                    <>
                        <InputText keyfilter={/[\d\w_]+/} />
                        <Button
                            icon="pi pi-check"
                            className="p-button-rounded p-button-primary"
                        />
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-primary"
                        />
                    </>
                ) : (
                    <>
                        <span className="text-xl">
                            {data && data.vminfo.name}
                        </span>
                        <RightChecker right="5">
                            <i
                                className="pi pi-pencil ml-1"
                                onClick={() => {}}
                            ></i>
                        </RightChecker>
                    </>
                )}
                <div className="ml-3">
                    {serverStatusChange.isChangeing ? (
                        <>
                            <Chip
                                label="Loading"
                                icon="pi pi-play"
                                className="text-success ms-2"
                            />
                        </>
                    ) : (
                        <>
                            {data?.vmstatus.status === "running" ? (
                                <Chip
                                    label="Läuft"
                                    icon="pi pi-play"
                                    className="text-success ms-2 text-primary bg-primary"
                                />
                            ) : (
                                <Chip
                                    label="Gestoppt"
                                    icon="pi pi-pause"
                                    className="text-danger ms-2 text-orange-100 bg-orange-300"
                                />
                            )}
                        </>
                    )}
                </div>
                <RightChecker right="9">
                    <span className="p-buttonset ml-auto">
                        <Button
                            label="Start Server"
                            icon="pi pi-check"
                            disabled={data?.vmstatus.status === "running"}
                            onClick={() => {
                                serveractions.mutate({ type: "start" });
                                setServerStatusChange({
                                    ...serverStatusChange,
                                    ...{ isChangeing: true },
                                });
                            }}
                        />
                        <Button
                            label="Stop Server"
                            icon="pi pi-power-off"
                            disabled={data?.vmstatus.status !== "running"}
                            onClick={() => {
                                serveractions.mutate({ type: "stop" });
                                setServerStatusChange({
                                    ...serverStatusChange,
                                    ...{ isChangeing: true },
                                });
                            }}
                        />
                        <Button
                            label="Reboot"
                            icon="pi pi-undo"
                            onClick={() =>
                                serveractions.mutate({ type: "reboot" })
                            }
                        />
                    </span>
                </RightChecker>
            </div>
            <div
                className="p-container mt-3 "
                style={{ maxWidth: "1400px", margin: "auto" }}
            >
                <TabView className="h-100" scrollable>
                    <TabPanel
                        header={
                            <span className="flex justify-content-center p-2">
                                <BiServer className="mr-2" />
                                Infos
                            </span>
                        }
                    >
                        <div className="grid">
                            <div className="col-6">
                                <div className="surface-0 p-4 shadow-2 border-round">
                                    <div className="grid p-nogutter align-center">
                                        <div className="col me-2">
                                            <div className="text-lg font-weight-bold text-primary text-uppercase mb-1">
                                                <FiCpu className="mr-1" />
                                                vCPU Kerne
                                            </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                <>
                                                    {data &&
                                                        data.vminfo.sockets *
                                                            data.vminfo.cores}
                                                </>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <RightChecker right="5">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-text ml-1"
                                                    onClick={() =>
                                                        setChangeCpuDialogVisible(
                                                            true
                                                        )
                                                    }
                                                />
                                                {data && (
                                                    <ServerChangeCpuDialog
                                                        id={parseInt(params.id)}
                                                        cpu={
                                                            data.vminfo
                                                                .sockets *
                                                            data.vminfo.cores
                                                        }
                                                        visible={
                                                            changeCpuDialogVisibible
                                                        }
                                                        onHide={() =>
                                                            setChangeCpuDialogVisible(
                                                                false
                                                            )
                                                        }
                                                    />
                                                )}
                                            </RightChecker>
                                        </div>
                                    </div>
                                    <div className="flex flex-column align-items-center justify-content-center">
                                        <Knob
                                            valueColor={
                                                !data
                                                    ? "var(--cyan-400)"
                                                    : data.vmstatus.cpu * 100 <
                                                      40
                                                    ? "var(--green-400)"
                                                    : data.vmstatus.cpu * 100 <
                                                      60
                                                    ? "var(--orange-400)"
                                                    : "var(--pink-400)"
                                            }
                                            value={
                                                data &&
                                                parseFloat(
                                                    (
                                                        data.vmstatus.cpu * 100
                                                    ).toFixed(0)
                                                )
                                            }
                                            valueTemplate={"{value}%"}
                                            min={0}
                                            max={100}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="surface-0 p-4 shadow-2 border-round">
                                    <div className="grid p-nogutter align-center">
                                        <div className="col me-2">
                                            <div className="text-lg font-weight-bold text-primary text-uppercase mb-1">
                                                <FaMemory className="mr-1" />
                                                vRAM
                                            </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                {data &&
                                                    data.vminfo.memory /
                                                        1024}{" "}
                                                (GB)
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <RightChecker right="5">
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="p-button-rounded p-button-text ml-1"
                                                    onClick={() =>
                                                        setChangeRamDialogVisible(
                                                            true
                                                        )
                                                    }
                                                />
                                                {data && (
                                                    <ServerChangeRamDialog
                                                        id={parseInt(params.id)}
                                                        ram={data.vminfo.memory}
                                                        visible={
                                                            changeRamDialogVisibible
                                                        }
                                                        onHide={() =>
                                                            setChangeRamDialogVisible(
                                                                false
                                                            )
                                                        }
                                                    />
                                                )}
                                            </RightChecker>
                                        </div>
                                    </div>
                                    <div className="flex flex-column align-items-center justify-content-center">
                                        <Knob
                                            valueColor={
                                                !data
                                                    ? "var(--cyan-400)"
                                                    : (data.vmstatus.mem /
                                                          data.vmstatus
                                                              .maxmem) *
                                                          100 <
                                                      40
                                                    ? "var(--green-400)"
                                                    : (data.vmstatus.mem /
                                                          data.vmstatus
                                                              .maxmem) *
                                                          100 <
                                                      60
                                                    ? "var(--orange-400)"
                                                    : "var(--pink-400)"
                                            }
                                            value={
                                                data &&
                                                data.vmstatus.status ===
                                                    "running"
                                                    ? parseInt(
                                                          (
                                                              (data.vmstatus
                                                                  .mem /
                                                                  data.vmstatus
                                                                      .maxmem) *
                                                              100
                                                          ).toFixed(0)
                                                      )
                                                    : 0
                                            }
                                            valueTemplate={"{value}%"}
                                            min={0}
                                            max={100}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Card
                                    className={`shadow-sm h100 p-shadow-2 os-win os-selection ${
                                        data?.vminfo.ostype == "win10"
                                            ? "os-win"
                                            : data?.vminfo.ostype == "l26"
                                            ? "os-ubu"
                                            : "os-no"
                                    }`}
                                >
                                    {data?.vminfo.ostype == "win10" ? (
                                        <FaWindows />
                                    ) : data?.vminfo.ostype == "l26" ? (
                                        <FaUbuntu />
                                    ) : (
                                        <GrSystem />
                                    )}
                                </Card>
                            </div>
                            <div className="col-12">
                                <div className="surface-0 p-4 shadow-2 border-round">
                                    <div className="col me-2">
                                        <div className="text-lg font-weight-bold text-primary text-uppercase mb-1">
                                            <FiCpu className="mr-1" />
                                            Festplatten
                                        </div>
                                    </div>
                                    {data && (
                                        <ServerDiskList
                                            vmid={parseInt(params.id)}
                                            vminfo={data.vminfo}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel
                        header={
                            <span className="flex justify-content-center p-2">
                                <BiTachometer className="mr-2" />
                                Auslastung
                            </span>
                        }
                    >
                        <ServerRessourceUssage vmid={params.id} />
                    </TabPanel>
                    <TabPanel
                        header={
                            <span className="flex justify-content-center p-2">
                                <BiRedo className="mr-2" />
                                Backups
                            </span>
                        }
                    >
                        <ServerBackups
                            vmid={params.id}
                            name={data ? data.vminfo.name : ""}
                            pool={"moso"} // todo
                        />
                    </TabPanel>
                    <TabPanel
                        header={
                            <span className="flex justify-content-center p-2">
                                <BiHistory className="mr-2" />
                                Verlauf
                            </span>
                        }
                    >
                        <ServerVerlauf vmid={params.id} />
                    </TabPanel>
                    <TabPanel
                        header={
                            <span className="flex justify-content-center p-2">
                                <BiDollarCircle />
                                Tarif
                            </span>
                        }
                    >
                        <ServerTarifDetails vmid={params.id} />
                    </TabPanel>
                    {/* <TabPanel header="Kosten">
                        <ServerCost vmid={params.id} />
                    </TabPanel> */}
                </TabView>

                {/* <RightChecker right="5">
                    <ServerChangeRam
                        ram={this.state.memory}
                        visible={this.state.changeRamVisible}
                        changeRam={this.changeRam}
                        onHide={() => {
                            this.onHide("changeRamVisible");
                        }}
                    />

                    <ServerChangeCPU
                        CPU={(
                            parseFloat(this.state.sockets) *
                            parseFloat(this.state.cores)
                        ).toFixed(2)}
                        visible={this.state.changeCPUVisible}
                        changeCPU={this.changeCPU}
                        onHide={() => {
                            this.onHide("changeCPUVisible");
                        }}
                    />
                </RightChecker> */}
            </div>
        </div>
    );
};

export default ServerDetailPage;
