import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart } from "primereact/chart";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { proxGetRrdData } from "logik/nodeApiCalls";
import Loading from "components/shared/Loading";

export default class ServerRessourceUssage extends Component {
    static propTypes = {
        vmid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            timeframe: "day",
            cpu: [],
            mem: [],
            diskread: [],
            diskwrite: [],
            netin: [],
            netout: [],
            times: [],
        };

        this.getChart1Data = this.getChart1Data.bind(this);
        this.getChart2Data = this.getChart2Data.bind(this);
        this.getChart3Data = this.getChart3Data.bind(this);
        this.getTimes = this.getTimes.bind(this);
    }

    componentDidMount() {
        this.getAuslastung("day");
    }

    render() {
        const timeframes = [
            { label: "Stunde", value: "hour" },
            { label: "Tag", value: "day" },
            { label: "Woche", value: "week" },
            { label: "Monat", value: "month" },
            { label: "Jahr", value: "year" },
        ];

        return (
            <div>
                <Dropdown
                    optionLabel="label"
                    value={this.state.timeframe}
                    options={timeframes}
                    onChange={(e) => this.getAuslastung(e.value)}
                />
                {this.state.loading && (
                    <div class="loadingContainer flex justify-content-center">
                        <Loading size="20vw" />
                    </div>
                )}
                {!this.state.loading && (
                    <>
                        <Chart
                            style={{ maxWidth: "800px", margin: "auto" }}
                            type="line"
                            data={this.getChart1Data()}
                        />
                        <Chart
                            style={{ maxWidth: "800px", margin: "auto" }}
                            type="line"
                            data={this.getChart2Data()}
                        />
                        <Chart
                            style={{ maxWidth: "800px", margin: "auto" }}
                            type="line"
                            data={this.getChart3Data()}
                        />
                    </>
                )}
            </div>
        );
    }

    getTimes() {
        if (
            this.state.timeframe === "month" ||
            this.state.timeframe === "year"
        ) {
            return this.state.dates;
        }
        return this.state.times;
    }

    async getAuslastung(timeframe) {
        this.setState({ timeframe: timeframe });
        var rrdData = await proxGetRrdData(this.props.vmid, timeframe);
        console.log(rrdData);
        var cpu = [],
            mem = [],
            diskread = [],
            diskwrite = [],
            netin = [],
            netout = [],
            times = [],
            dates = [];
        rrdData.forEach((element) => {
            cpu.push(element.cpu);
            mem.push(element.mem / 100000000);
            diskread.push(element.diskread);
            diskwrite.push(element.diskwrite);
            netin.push(element.netin);
            netout.push(element.netout);
            dates.push(
                DateTime.fromSeconds(element.time).toFormat("yyyy LLL dd")
            );
            times.push(
                DateTime.fromSeconds(element.time).toFormat("LLL dd, H:mm")
            );
        });

        this.setState({
            cpu: cpu,
            mem: mem,
            diskread: diskread,
            diskwrite: diskwrite,
            netin: netin,
            netout: netout,
            times: times,
            dates: dates,
            loading: false,
        });
    }

    getChart1Data() {
        return {
            labels: this.getTimes(),
            datasets: [
                {
                    label: "CPU",
                    data: this.state.cpu,
                    fill: false,
                    borderColor: "#4bc0c0",
                    tension: 0.4,
                },
                {
                    label: "Ram",
                    data: this.state.mem,
                    fill: false,
                    borderColor: "#565656",
                    tension: 0.4,
                },
            ],
        };
    }

    getChart2Data() {
        return {
            labels: this.getTimes(),
            datasets: [
                {
                    label: "Disk Read",
                    data: this.state.diskread,
                    fill: false,
                    borderColor: "#4bc0c0",
                    tension: 0.4,
                },
                {
                    label: "Disk Write",
                    data: this.state.diskwrite,
                    fill: false,
                    borderColor: "#565656",
                    tension: 0.4,
                },
            ],
        };
    }

    getChart3Data() {
        return {
            labels: this.getTimes(),
            datasets: [
                {
                    label: "Net In",
                    data: this.state.netin,
                    fill: false,
                    borderColor: "#4bc0c0",
                    tension: 0.4,
                },
                {
                    label: "Net Out",
                    data: this.state.netout,
                    fill: false,
                    borderColor: "#565656",
                    tension: 0.4,
                },
            ],
        };
    }
}
