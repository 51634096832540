import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { withRouter } from "react-router";
import "./login.css";
import { Toast } from "primereact/toast";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Button } from "primereact/button";
class ActivateMFA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tel: "",
            smsSent: false,
            code: "",
            verificationId: "",
        };
        this.activateMFA = this.activateMFA.bind(this);
        this.confirmCode = this.confirmCode.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        if (error.code === "auth/invalid-verification-code") {
            this.toast.show({
                severity: "error",
                summary: "Falscher Code",
                detail: "Bitte überprüfen Sie Ihre Eingabe",
            });
        } else if (error.code === "auth/user-disabled") {
            this.toast.show({
                severity: "error",
                summary: "Account deaktiviert",
                detail: "Ihr Account wurde deaktiviert",
            });
        } else if (error.code === "auth/user-not-found") {
            this.toast.show({
                severity: "error",
                summary: "Account nicht gefunden",
                detail: "Ihr Account wurde nicht gefunden",
            });
        } else if (error.code === "auth/wrong-password") {
            this.toast.show({
                severity: "error",
                summary: "Falsches Passwort",
                detail: "Bitte überprüfen Sie Ihre Eingabe",
            });
        } else if (error.code === "auth/invalid-phone-number") {
            this.toast.show({
                severity: "error",
                summary: "Ungültige Telefonnummer",
                detail: "Bitte überprüfen Sie Ihre Eingabe",
            });
        } else if (error.code === "auth/requires-recent-login") {
            this.toast.show({
                severity: "error",
                summary: "Login erforderlich",
                detail: "Bitte loggen Sie sich erneut ein",
            });
            //this.props.history.push("/login");
        }
        console.log(error, errorInfo);
    }

    render() {
        return (
            <>
                <Toast ref={(el) => (this.toast = el)} />
                <div className="p-text-center">
                    <h1 className="h4 text-gray-900 mb-4">
                        {firebase.auth().currentUser.emailVerified ? (
                            <>
                                {this.state.smsSent ? (
                                    <>
                                        <br />
                                        Bestätigen Sie den SMS Code
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        Sie Benötigen noch eine
                                        2FA-Authentifizierung{" "}
                                    </>
                                )}
                            </>
                        ) : (
                            <>Bitte bestätigen Sie die E-Mail-Adresse</>
                        )}
                    </h1>
                </div>
                {firebase.auth().currentUser.emailVerified ? (
                    <>
                        {this.state.smsSent ? (
                            <form
                                id="bestaetigung"
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <div className="p-text-center">
                                    <InputText
                                        placeholder="Bestätigungscode"
                                        value={this.state.code}
                                        onChange={(e) =>
                                            this.setState({
                                                code: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-text-center">
                                    <Button
                                        label="Bestätigungscode nehmen"
                                        id=""
                                        onClick={this.confirmCode}
                                    />
                                </div>
                            </form>
                        ) : (
                            <form
                                id="telefonnr"
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <div className="p-text-center">
                                    <InputText
                                        placeholder="Telefonnummer"
                                        value={this.state.tel}
                                        onChange={(e) =>
                                            this.setState({
                                                tel: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-text-center">
                                    <Button
                                        label="Diese Nummer zur 2FA nutzen"
                                        id="recap"
                                        onClick={this.activateMFA}
                                    />
                                </div>
                            </form>
                        )}
                    </>
                ) : (
                    <>
                        <Button
                            label="E-Mail bestätigung erneut senden"
                            onClick={() => {
                                firebase
                                    .auth()
                                    .currentUser.sendEmailVerification()
                                    .then(() => {
                                        // Email verification sent!
                                        // ...
                                        console.log("verfication sent");
                                    });
                            }}
                        />
                    </>
                )}
            </>
        );
    }

    async confirmCode() {
        try {
            var cred = firebase.auth.PhoneAuthProvider.credential(
                this.state.verificationId,
                this.state.code
            );
            var multiFactorAssertion =
                firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
            // Complete enrollment.
            await firebase
                .auth()
                .currentUser.multiFactor.enroll(
                    multiFactorAssertion,
                    "mfaDisplayName"
                )
                .catch((error) => {
                    if (error.code === "auth/invalid-verification-code") {
                        this.toast.show({
                            severity: "error",
                            summary: "Falscher Code",
                            detail: "Bitte überprüfen Sie Ihre Eingabe",
                        });
                    }
                    console.log(error);
                });
            //this.props.history.push("/dashboard");
        } catch (err) {
            console.log(err);
        }
    }

    async activateMFA() {
        var tel = this.state.tel;
        var recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recap", {
            size: "invisible",
            callback: function (response) {
                console.log("recaptcha suxccsses");
                // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                //this.login();
            },
        });
        var user = firebase.auth().currentUser;
        var multiFactorSession = await user.multiFactor.getSession();

        var phoneInfoOptions = {
            phoneNumber: tel,
            session: multiFactorSession,
        };
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        var verificationId = await phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .catch((error) => {
                if (error.code === "auth/invalid-phone-number") {
                    this.toast.show({
                        severity: "error",
                        summary: "Ungültige Telefonnummer",
                        detail: "Bitte überprüfen Sie Ihre Eingabe",
                    });
                } else if (error.code === "auth/requires-recent-login") {
                    this.toast.show({
                        severity: "error",
                        summary: "Login erforderlich",
                        detail: "Bitte loggen Sie sich erneut ein",
                    });
                    //this.props.history.push("/login");
                } else if (error.code === "auth/user-disabled") {
                    this.toast.show({
                        severity: "error",
                        summary: "Account deaktiviert",
                        detail: "Ihr Account wurde deaktiviert",
                    });
                } else if (error.code === "auth/user-not-found") {
                    this.toast.show({
                        severity: "error",
                        summary: "Account nicht gefunden",
                        detail: "Ihr Account wurde nicht gefunden",
                    });
                } else {
                    console.error(error);
                }
            });
        console.log(firebase.auth().currentUser);
        console.log(verificationId);
        if (verificationId) {
            this.setState({
                verificationId: verificationId,
                smsSent: true,
            });
        }
    }
}

export default withRouter(ActivateMFA);
