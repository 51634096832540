import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";
import { proxGetPools } from "logik/nodeApiCalls";

export default class PoolSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            pool: this.props.value,
            pools: this.props.pools,
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.getPools();
    }

    render() {
        return (
            <>
                <Dropdown
                    value={this.state.pool}
                    options={this.state.pools}
                    onChange={this.onChange}
                    disabled={this.props.disabled}
                    filter
                    filterBy="pool"
                    optionLabel="pool"
                    placeholder="Wählen Sie einen Kunden aus"
                    className="w-full"
                />
            </>
        );
    }

    onChange(e) {
        this.setState({ pool: e.value });
        this.props.onChange(e.value);
    }

    async getPools() {
        var pools = await proxGetPools();
        var array = [];
        pools.forEach((pool) => {
            array.push({ pool: pool });
        });
        this.setState({ pools: array });
        console.log(pools);
    }
}
