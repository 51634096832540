import { useState, useRef, ReactNode } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import "firebase/compat/auth";
import AdminSidebar from "./AdminSidebar";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

export type Menu = {
    std: MenuCategory;
    admin: MenuCategory;
    views: MenuCategory;
    migration: MenuCategory;
};

export type MenuCategory = {
    name: string;
    menuItems: { title: string; icon: string; to: string }[];
};

const Layout = ({ children, menu }: { menu: Menu; children: ReactNode }) => {
    const [adminMenuVisible, setAdminMenuVisible] = useState<boolean>(false);

    const hideAdminMenu = () => {
        setAdminMenuVisible(false);
    };

    return (
        <div className="h-screen flex flex-column surface-ground">
            <Navbar setAdminMenuVisible={setAdminMenuVisible} />
            <ul className="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium border-top-1 border-bottom-1 surface-border">
                <li className="pr-3">
                    <a className="cursor-pointer">
                        <i className="pi pi-home text-600"></i>
                    </a>
                </li>
                <li className="px-2">
                    <i className="pi pi-angle-right text-600"></i>
                </li>
                <li className="px-2">
                    <a className="cursor-pointer text-cyan-500 white-space-nowrap">
                        Dashboard
                    </a>
                </li>
            </ul>
            <div className="flex relative lg:static p-5 flex-auto">
                <Sidebar stdmenu={menu.std} />
                <div className="flex flex-column flex-auto">
                    <div className="border-2 p-2 surface-border border-round surface-section flex-auto lg:ml-5">
                        <ScrollPanel
                            style={{
                                width: "100%",
                                height: "calc(100vh - 220px)",
                            }}
                        >
                            {children}
                        </ScrollPanel>
                    </div>
                </div>
                <AdminSidebar
                    visible={adminMenuVisible}
                    hide={hideAdminMenu}
                    adminMenu={menu.admin}
                    viewMenu={menu.views}
                    migrationMenu={menu.migration}
                />
            </div>
        </div>
    );
};

export default Layout;
