import { Card } from "primereact/card";
import { useState } from "react";
import UserAutocomplete from "../manuelleEinpflegung/UserAutocomplete";
type Props = {};

const PoolManagement = (props: Props) => {
    const [pool, setPool] = useState("");
    return (
        <div>
            <UserAutocomplete onSelect={() => setPool} />
            <div className="grid">
                <Card className="col-12 py-6 px-2 my-2">VIMS</Card>
                <Card className="col-6  py-6  px-2 my-2">Zertifikate</Card>
                <Card className="col-6  py-6 px-2 my-2">
                    Zusatztarife Innocigs
                </Card>
                <Card className="col-6  py-6 px-2 my-2">
                    Zusatztarife Webhosting
                </Card>
                <Card className="col-6  py-6 px-2 my-2">Domains</Card>
            </div>
        </div>
    );
};

export default PoolManagement;
