import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
    onClick: (() => void) | undefined;
    classNames: string;
    to: string;
    icon: string;
    title: string;
};
//  "sidebarSection"
const SidebarLink = ({ to, icon, classNames, title, onClick }: Props) => {
    return (
        <li className={classNames}>
            <NavLink
                onClick={() => {
                    onClick && onClick();
                }}
                to={to}
                className={`p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100  transition-duration-150 transition-colors`}
            >
                <i className={`${icon} mr-2`}></i>
                <span className="font-medium">{title}</span>
            </NavLink>
        </li>
    );
};

export default SidebarLink;
