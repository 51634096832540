import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef } from "react";
import {
    TarifFlextarifTarifFlextarifTovimFkFlexTarif,
    TarifStandardtarifTarifStandardtarifTovimFkTarif,
} from "./ShowUserTariff";

type Props = {
    tarif: TarifStandardtarifTarifStandardtarifTovimFkTarif | null;
    flexTarif: TarifFlextarifTarifFlextarifTovimFkFlexTarif | null;
};

const StandardTarifDetailsOverlay = (props: Props) => {
    const op = useRef<OverlayPanel>(null);

    return (
        <>
            {props.tarif && (
                <>
                    <Button
                        className="w-full"
                        label={props.tarif.cName}
                        onClick={(e) => op.current?.toggle(e)}
                    />
                    <OverlayPanel
                        style={{ width: "720px" }}
                        ref={op}
                        showCloseIcon
                        dismissable
                    >
                        <div className="grid">
                            <div className="col-6">
                                <h3>Standard</h3>
                                <table>
                                    <tr>
                                        <td>CPUs:</td>
                                        <td>{props.tarif.nCpu}</td>
                                    </tr>
                                    <tr>
                                        <td>RAM:</td>
                                        <td>{props.tarif.nCpu}</td>
                                    </tr>
                                    <tr>
                                        <td>Mindest Laufzeit in Monaten:</td>
                                        <td>{props.tarif.nMinMonthRuntime}</td>
                                    </tr>
                                    <tr>
                                        <td>Speicher High Performance:</td>
                                        <td>
                                            {
                                                props.tarif
                                                    .nSpeicherHighPerformance
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Speicher Normal Performance:</td>
                                        <td>
                                            {
                                                props.tarif
                                                    .nSpeicherNormalPerformance
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-6">
                                <h3>Flex:</h3>
                                <table>
                                    <tr>
                                        <td>CPU pro Kern:</td>
                                        <td>{props.flexTarif?.fCpuPrice} €</td>
                                    </tr>
                                    <tr>
                                        <td>RAM:</td>
                                        <td>{props.flexTarif?.fRamPrice}€</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Speicher High Performance pro GB:
                                        </td>
                                        <td>
                                            {
                                                props.flexTarif
                                                    ?.fSpeicherHighPerformancePrice
                                            }
                                            €
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Speicher Normal Performance:</td>
                                        <td>
                                            {props.flexTarif
                                                ?.fSpeicherNormalPerformancePrice +
                                                "€"}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </OverlayPanel>
                </>
            )}
        </>
    );
};

export default StandardTarifDetailsOverlay;
