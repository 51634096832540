import ConfirmButton from "components/shared/ConfirmButton";
import IsoDateParser from "components/shared/IsoDateParser";
import {
    apiCancelAbo,
    apiCancelCerts,
    apiCancelTarif,
    apiwithDrawTarif,
    withDrawAbo,
    withDrawCerts,
} from "logik/nodeApiCalls";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import ShowAbo, { AboInterface } from "./ShowAbo";
import { CertInterface } from "./ShowCertificates";
import { TarifInterface } from "./ShowUserTariff";
import { Divider } from "primereact/divider";

type Props = {
    abos: never[][];
    tarifs: never[][];
    certs: never[][];
};

type State = {
    displayBasic: boolean;
};

export default class DeleteButtonGlobal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            displayBasic: false,
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        console.log(this.props.abos);
        console.log(this.props.certs);
        console.log(this.props.tarifs);
    }

    render() {
        return (
            <>
                <Button
                    className="deleteButtonGlobal"
                    label="Kündigunsinterface"
                    aria-label="Kündigunsinterface"
                    onClick={() => this.onClick("displayBasic")}
                />
                <Dialog
                    header="Wähle die zu kündigenden Einträge"
                    visible={this.state.displayBasic}
                    style={{ width: "80vw" }}
                    onHide={() => this.onHide("displayBasic")}
                >
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Abos</b>
                        </div>
                    </Divider>

                    <DataTable
                        stripedRows
                        value={this.props.abos}
                        responsiveLayout="scroll"
                        paginator
                        rows={5}
                    >
                        <Column field="cDescription" header="Name"></Column>
                        <Column field="cType" header="Typ"></Column>
                        <Column
                            field="dErstellt"
                            header="Erstellt"
                            body={(rowData) => (
                                <IsoDateParser isoDate={rowData.dErstellt} />
                            )}
                        />
                        <Column
                            headerStyle={{ width: "3em" }}
                            body={(rowdata: AboInterface) => {
                                if (rowdata.dAboBeendetAm !== null) {
                                    return (
                                        <ConfirmButton
                                            btnLabel="Wiederrufen"
                                            btnIcon="pi pi-replay"
                                            btnClassNames="w-full"
                                            confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen?"
                                            confirmHeader="Bestätigung"
                                            confirmAccept={() =>
                                                withDrawAbo(rowdata.kAbo)
                                            }
                                        />
                                    );
                                }

                                return (
                                    <ConfirmButton
                                        btnLabel="Kündigen"
                                        btnIcon="pi pi-times"
                                        btnClassNames="p-button-danger w-full"
                                        confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                        confirmHeader="Bestätigung"
                                        confirmAccept={() =>
                                            apiCancelAbo(
                                                rowdata.kAbo,
                                                rowdata.dErstellt,
                                                rowdata.kcPoolName
                                            )
                                        }
                                    />
                                );
                            }}
                        ></Column>
                    </DataTable>
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Tarife</b>
                        </div>
                    </Divider>
                    <DataTable
                        stripedRows
                        value={this.props.tarifs}
                        responsiveLayout="scroll"
                        paginator
                        rows={5}
                    >
                        <Column field="nName" header="Nodename"></Column>
                        <Column field="cTariff" header="Typ"></Column>
                        <Column field="fPreis" header="Preis"></Column>
                        <Column
                            headerStyle={{ width: "3em" }}
                            body={(rowdata: TarifInterface) => {
                                if (rowdata.bGekuendigt) {
                                    return (
                                        <ConfirmButton
                                            btnLabel="Wiederrufen"
                                            btnIcon="pi pi-replay"
                                            btnClassNames="w-full"
                                            confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen?"
                                            confirmHeader="Bestätigung"
                                            confirmAccept={() =>
                                                apiwithDrawTarif(rowdata.kVim)
                                            }
                                        />
                                    );
                                }
                                return (
                                    <ConfirmButton
                                        btnLabel="Kündigen"
                                        btnIcon="pi pi-times"
                                        btnClassNames="p-button-danger w-full"
                                        confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                        confirmHeader="Bestätigung"
                                        confirmAccept={() =>
                                            apiCancelTarif(rowdata.kVim)
                                        }
                                    />
                                );
                            }}
                        ></Column>
                    </DataTable>
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Zertifikate</b>
                        </div>
                    </Divider>
                    <DataTable
                        stripedRows
                        value={this.props.certs}
                        responsiveLayout="scroll"
                        paginator
                        rows={5}
                    >
                        <Column field="Domain" header="Domain"></Column>
                        <Column field="cName" header="Typ"></Column>
                        <Column field="nID" header="ID"></Column>
                        <Column
                            headerStyle={{ width: "3em" }}
                            body={(rowdata: CertInterface) => {
                                if (rowdata.bGekuendigt !== null) {
                                    return (
                                        <ConfirmButton
                                            btnLabel="Wiederrufen"
                                            btnIcon="pi pi-replay"
                                            btnClassNames="w-full"
                                            confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen?"
                                            confirmHeader="Bestätigung"
                                            confirmAccept={() =>
                                                withDrawCerts(
                                                    rowdata.kCertificate
                                                )
                                            }
                                        />
                                    );
                                }
                                return (
                                    <ConfirmButton
                                        btnLabel="Kündigen"
                                        btnIcon="pi pi-times"
                                        btnClassNames="p-button-danger w-full"
                                        confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                        confirmHeader="Bestätigung"
                                        confirmAccept={() =>
                                            apiCancelCerts(rowdata.kCertificate)
                                        }
                                    />
                                );
                            }}
                        ></Column>
                    </DataTable>
                </Dialog>
            </>
        );
    }

    onClick(name: any) {
        this.setState({
            displayBasic: true,
        });
    }

    onHide(name: string) {
        this.setState({
            displayBasic: false,
        });
    }
}
