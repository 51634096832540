import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { MultiSelect } from "primereact/multiselect";
import DomainCounter from "./DomainCounter";
import TLDCounter from "./TLDCounter";
import YearlyCounter from "./YearlyCounter";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import DeleteButton from "./DeleteButton";
import SetSPLA from "./SetSPA";
import {
    adminPoolList,
    adminShowAbos,
    apiCancelAbo,
    withDrawAbo,
} from "logik/nodeApiCalls";
import WithDrawTermination from "./WithDrawTermination";
import { DateTime } from "luxon";
import ConfirmButton from "../../shared/ConfirmButton";

export interface AboInterface {
    kAbo: number;
    cDescription: string;
    dErstellt: string;
    fPreisProAbrechnugszeitraum: string;
    nAbrechnungszeitraumInTagen: string;
    dAboBeendetAm: Date;
    cType: string;
    kcPoolName: string;
    KDN: string;
    cFirma: string;
}

export interface PoolInterface {
    kcPoolName: string;
}

type Props = {};

type State = {
    abos: AboInterface[];
    pools: PoolInterface[];
    // globalFilterValue1: string;
    //   filters1: null,
};

export default class ShowDomains extends Component<Props, State> {
    state = {
        abos: [],
        pools: [{ kcPoolName: "moso" }],
        // globalFilterValue1: "",
        // filters1: null
    };

    constructor(props: Props) {
        super(props);

        this.showDomainList = this.showDomainList.bind(this);
        this.poolList = this.poolList.bind(this);
    }

    componentDidMount() {
        this.showDomainList();
    }

    // onGlobalFilterChange1(e: []) {
    //   const value = e.target.value;
    //   let filters1 = {this.state.filters1};
    //   filters1["global"].value = value;

    //   this.setState({ filters1, globalFilterValue1: value });
    // }

    render() {
        return (
            <div>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <DomainCounter />
                                <div
                                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                    style={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                    }}
                                >
                                    <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <YearlyCounter />
                                <div
                                    className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                    style={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                    }}
                                >
                                    <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    stripedRows
                    value={this.state.abos}
                    responsiveLayout="scroll"
                    paginator
                    rows={15}
                    rowsPerPageOptions={[15, 30, 45, 60, 75, 90]}
                    filterDisplay="row"
                >
                    <Column
                        field="cDescription"
                        header="Domainname"
                        sortable
                        showFilterMatchModes={true}
                        filter
                    />
                    <Column
                        field="dErstellt"
                        header="Erstellt am"
                        sortable
                        filter
                        body={(rowdata: AboInterface) => (
                            <Tag
                                severity="info"
                                value={DateTime.fromISO(rowdata.dErstellt, {
                                    zone: "utc",
                                })
                                    .toFormat("dd/MM/yyyy")
                                    .toString()}
                            />
                        )}
                    />
                    <Column
                        field="dErstellt"
                        header="Gültig bis "
                        sortable
                        filter
                        body={(rowdata: AboInterface) => (
                            <Tag
                                severity="info"
                                value={DateTime.fromISO(rowdata.dErstellt, {
                                    zone: "utc",
                                })
                                    .plus({ years: 1 })
                                    .toFormat("dd/MM/yyyy")
                                    .toString()}
                            />
                        )}
                    />
                    <Column
                        field="fPreisProAbrechnugszeitraum"
                        header="Preis"
                        sortable
                        filter
                        body={(rowdata: AboInterface) => (
                            <Tag
                                value={
                                    isNaN(
                                        parseInt(
                                            rowdata.fPreisProAbrechnugszeitraum
                                        )
                                    ) ? (
                                        <>/ €</>
                                    ) : (
                                        parseInt(
                                            rowdata.fPreisProAbrechnugszeitraum
                                        ).toFixed(2) + " €"
                                    )
                                }
                            ></Tag>
                        )}
                    />
                    <Column
                        field="nAbrechnungszeitraumInTagen"
                        header="Abrechnungszeitraum"
                        sortable
                        filter
                    />
                    <Column
                        field="dAboBeendetAm"
                        header="Gekündigt am"
                        sortable
                        filter
                        body={(rowdata: AboInterface) => (
                            <Tag
                                severity={
                                    rowdata.dAboBeendetAm !== null
                                        ? "danger"
                                        : "info"
                                }
                                value={
                                    rowdata.dAboBeendetAm !== null
                                        ? rowdata.dAboBeendetAm
                                              .toString()
                                              .substring(8, 10) +
                                          "/" +
                                          rowdata.dAboBeendetAm
                                              .toString()
                                              .substring(5, 7) +
                                          "/" +
                                          rowdata.dAboBeendetAm
                                              .toString()
                                              .substring(0, 4)
                                        : "Fortlaufend"
                                }
                            ></Tag>
                        )}
                    />
                    <Column
                        field="cType"
                        header="Typ"
                        sortable
                        filter
                        body={(rowdata: AboInterface) => (
                            <Tag value={rowdata.cType}></Tag>
                        )}
                    />
                    <Column
                        field="kcPoolName"
                        header="Pool"
                        sortable
                        showFilterMatchModes={true}
                        filter
                    />
                    <Column
                        field="KDN"
                        header="KDN"
                        sortable
                        filter
                        body={(rowdata: AboInterface) => (
                            <Button
                                className="p-button-text"
                                label={"" + rowdata.KDN}
                                tooltip={rowdata.cFirma}
                            ></Button>
                        )}
                    />
                    <Column
                        body={(rowdata: AboInterface) => {
                            if (rowdata.dAboBeendetAm !== null) {
                                return (
                                    <ConfirmButton
                                        btnLabel="Wiederrufen"
                                        btnIcon="pi pi-replay"
                                        btnClassNames="w-full"
                                        confirmMessage="Möchten Sie die Kündigung wirklich wiederrufen?"
                                        confirmHeader="Bestätigung"
                                        confirmAccept={() =>
                                            this.withDrawAboHandler(
                                                rowdata.kAbo
                                            )
                                        }
                                    />
                                );
                            }

                            return (
                                <ConfirmButton
                                    btnLabel="Kündigen"
                                    btnIcon="pi pi-times"
                                    btnClassNames="p-button-danger w-full"
                                    confirmMessage="Möchten Sie den Tarif wirklich kündigen?"
                                    confirmHeader="Bestätigung"
                                    confirmAccept={() =>
                                        this.deleteAboHandler(
                                            rowdata.kAbo,
                                            rowdata.dErstellt,
                                            rowdata.kcPoolName
                                        )
                                    }
                                />
                            );
                        }}
                    />
                </DataTable>
            </div>
        );
    }

    async showDomainList() {
        let abos = await adminShowAbos();
        console.log(abos);
        this.setState({ abos: abos });
    }

    async deleteAboHandler(
        kAbo: number,
        dErstellt: string,
        kcPoolName: string
    ) {
        await apiCancelAbo(kAbo, dErstellt, kcPoolName);
        this.showDomainList();
    }

    async withDrawAboHandler(kAbo: number) {
        await withDrawAbo(kAbo);
        this.showDomainList();
    }

    async poolList() {
        let pools = await adminPoolList();
        this.setState({ pools: pools });
    }
}
