import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MenuItem } from "primereact/menuitem";
import { Steps } from "primereact/steps";
import React, { Component } from "react";
import DomainChecker from "./DomainChecker";
import { Calendar } from "primereact/calendar";
import {
    domainCreateHandles as domainCreateHandle,
    domainGetHandles,
} from "logik/apiCalls";
import { UserContext } from "App";
import PoolSelect from "components/server/shared/PoolSelect";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import withContext from "components/shared/withContext";
import Q from "q";
import Loading from "components/shared/Loading";
import { InputSwitch } from "primereact/inputswitch";
import { proxGetPools } from "logik/nodeApiCalls";
interface Props {
    withoutSteps?: boolean;
    onSelect?: (handle: any) => void;
    context: any;
}
interface State {
    activeStep: number;
    domain: string;
    handle: string;
    handles: { label: string; value: string }[];
    firma: string;
    vorname: string;
    name: string;
    strasse: string;
    postleitzahl: string;
    stadt: string;
    land: string;
    telefon: string;
    telefax: string;
    email: string;
    bundesland: string;
    steuerID: string;
    geburtstag: Date | undefined | Date[];
    handelsregister: string;
    pool: any;
    loading: boolean;
}

class RegisterDomain extends Component<Props, State> {
    items: Array<MenuItem>;

    state = {
        activeStep: 1,
        domain: "",
        firma: "",
        vorname: "",
        name: "",
        strasse: "",
        postleitzahl: "",
        stadt: "",
        land: "",
        telefon: "",
        telefax: "",
        email: "",
        bundesland: "",
        steuerID: "",
        geburtstag: new Date(),
        handelsregister: "",
        handle: "",
        pool: { pool: "" },
        handles: [{ label: "Neuen Kontakt anlegen", value: "new" }],
        loading: true,
    };
    domainRef: any;

    constructor(props: Props) {
        super(props);

        this.items = [
            {
                label: "Verfügbarkeit prüfen",
            },
            {
                label: "Kontakt Informationen",
            },
            {
                label: "Bestätigung",
            },
        ];
        this.domainRef = React.createRef();

        this.renderStep0 = this.renderStep0.bind(this);
        this.renderStep1 = this.renderStep1.bind(this);
        this.renderStep2 = this.renderStep2.bind(this);
        this.onVerfuegbar = this.onVerfuegbar.bind(this);
        this.onDomainChange = this.onDomainChange.bind(this);
        this.getHandles = this.getHandles.bind(this);
        this.setDefaultPoolIfNotReseller =
            this.setDefaultPoolIfNotReseller.bind(this);
        this.selectPool = this.selectPool.bind(this);
        this.saveHandle = this.saveHandle.bind(this);
    }

    componentDidMount() {
        this.getHandles();
    }

    render() {
        return (
            <div>
                {!this.props.withoutSteps && (
                    <Steps
                        className="ml-5 mr-5"
                        activeIndex={this.state.activeStep}
                        onSelect={(e) => this.setState({ activeStep: e.index })}
                        model={this.items}
                    />
                )}
                <br />

                {this.state.activeStep === 0 && this.renderStep0()}
                {this.state.activeStep === 1 && this.renderStep1()}
                {this.state.activeStep === 2 && this.renderStep2()}
            </div>
        );
    }

    renderStep0() {
        return (
            <DomainChecker
                ref={(el: any) => (this.domainRef = el)}
                onVerfuegbar={this.onVerfuegbar}
                onChange={this.onDomainChange}
            />
        );
    }
    renderStep1() {
        return (
            <>
                <div className="formgrid grid">
                    {this.state.loading && (
                        <div className="col-1">
                            <Loading size="70px" />
                        </div>
                    )}

                    <div
                        className={
                            "field" + this.state.loading ? "col-11" : "col-12"
                        }
                    >
                        <label htmlFor="">Vorhandenen Kontakt auswählen</label>
                        <Dropdown
                            dropdownIcon={
                                this.state.loading
                                    ? "pi pi-spinner pi-spin"
                                    : "pi pi-chevron-down"
                            }
                            placeholder="Lädt..."
                            className=" w-full"
                            options={this.state.handles}
                            value={this.state.handle}
                            onChange={(e) => this.setState({ handle: e.value })}
                        />
                    </div>
                    <Divider>
                        <InputSwitch
                            className="mr-3"
                            checked={this.state.handle === "new"}
                            onChange={(e) =>
                                this.setState({ handle: e.value ? "new" : "" })
                            }
                        ></InputSwitch>
                        <label>Neuen Kontakt anlegen</label>
                    </Divider>

                    <UserContext.Consumer>
                        {(value) =>
                            value.isReseller === "1" && (
                                <div className="field col-12">
                                    <label htmlFor="">Kunden auswählen</label>

                                    <PoolSelect
                                        disabled={this.state.handle !== "new"}
                                        onChange={this.selectPool}
                                    />
                                </div>
                            )
                        }
                    </UserContext.Consumer>

                    <div className="field col-12">
                        <label htmlFor="address">Firma</label>
                        <InputText
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.firma}
                            onChange={(e) =>
                                this.setState({
                                    firma: e.target.value,
                                })
                            }
                            type="text"
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="firstname6">Vorname</label>
                        <InputText
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.vorname}
                            onChange={(e) =>
                                this.setState({
                                    vorname: e.target.value,
                                })
                            }
                            id="firstname6"
                            type="text"
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="lastname6">Lastname</label>
                        <InputText
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.name}
                            onChange={(e) =>
                                this.setState({
                                    name: e.target.value,
                                })
                            }
                            id="lastname6"
                            type="text"
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="address">Strasse und Hausnummer</label>
                        <InputText
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.strasse}
                            onChange={(e) =>
                                this.setState({
                                    strasse: e.target.value,
                                })
                            }
                            type="text"
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="address">Telefon</label>
                        <InputMask
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.telefon}
                            onChange={(e) =>
                                this.setState({
                                    telefon: e.value,
                                })
                            }
                            mask="+49 999 ?999999999999999"
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="state">Land</label>
                        <Dropdown
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.land}
                            options={["DE", "AT", "CH"]}
                            onChange={(e) => this.setState({ land: e.value })}
                            placeholder="Select"
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="city">City</label>
                        <InputText
                            className=" w-full"
                            disabled={this.state.handle !== "new"}
                            value={this.state.stadt}
                            onChange={(e) =>
                                this.setState({
                                    stadt: e.target.value,
                                })
                            }
                            id="city"
                            type="text"
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="zip">PLZ</label>
                        <InputText
                            className=" w-full"
                            id="zip"
                            type="text"
                            value={this.state.postleitzahl}
                            onChange={(e) =>
                                this.setState({
                                    postleitzahl: e.target.value,
                                })
                            }
                            disabled={this.state.handle !== "new"}
                        />
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="zip">Email</label>
                        <InputText
                            className=" w-full"
                            value={this.state.email}
                            placeholder=""
                            type="email"
                            onChange={(e) =>
                                this.setState({
                                    email: e.target.value,
                                })
                            }
                            disabled={this.state.handle !== "new"}
                        ></InputText>
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="zip">Geburtstag</label>
                        <Calendar
                            className=" w-full"
                            dateFormat="dd.mm.yy"
                            value={this.state.geburtstag}
                            onChange={(e) =>
                                this.setState({
                                    geburtstag: e.value,
                                })
                            }
                            disabled={this.state.handle !== "new"}
                        ></Calendar>
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="zip">Bundesland</label>
                        <InputText
                            className=" w-full"
                            value={this.state.bundesland}
                            placeholder=""
                            onChange={(e) =>
                                this.setState({
                                    bundesland: e.target.value,
                                })
                            }
                            disabled={this.state.handle !== "new"}
                        ></InputText>
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="zip">SteuerID</label>
                        <InputText
                            className=" w-full"
                            value={this.state.steuerID}
                            placeholder=""
                            onChange={(e) =>
                                this.setState({
                                    steuerID: e.target.value,
                                })
                            }
                            disabled={this.state.handle !== "new"}
                        ></InputText>
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="zip">Handelsregister</label>
                        <InputText
                            className=" w-full"
                            value={this.state.handelsregister}
                            placeholder=""
                            onChange={(e) =>
                                this.setState({
                                    handelsregister: e.target.value,
                                })
                            }
                            disabled={this.state.handle !== "new"}
                        ></InputText>
                    </div>
                </div>
                <div className="p-card-footer text-end">
                    <Button
                        onClick={() => {
                            this.setState({
                                activeStep: this.state.activeStep - 1,
                            });
                        }}
                        label="Zurück"
                        icon="pi pi-arrow-left"
                        className="p-button-text"
                    />
                    <Button
                        id="confirmButton"
                        onClick={this.saveHandle}
                        disabled={
                            this.state.handle === "new" &&
                            (this.state.firma === "" ||
                                this.state.vorname === "" ||
                                this.state.name === "" ||
                                this.state.strasse === "" ||
                                this.state.postleitzahl === "" ||
                                this.state.stadt === "" ||
                                this.state.land === "" ||
                                this.state.telefon === "" ||
                                this.state.email === "" ||
                                this.state.pool.pool === "")
                        }
                        label="Weiter"
                        icon={"pi pi-check"}
                    />
                </div>
            </>
        );
    }

    async saveHandle() {
        if (this.state.handle === "new") {
            let res = await domainCreateHandle({
                handle: "new",
                firma: this.state.firma,
                vorname: this.state.vorname,
                name: this.state.name,
                strasse: this.state.strasse,
                stadt: this.state.stadt,
                postleitzahl: this.state.postleitzahl,
                land: this.state.land,
                telefon: this.state.telefon,
                email: this.state.email,
                pool: this.state.pool.pool,
            });
            if (this.props.onSelect) {
                this.props.onSelect(res.data);
            }
        } else {
            if (this.props.onSelect) {
                this.props.onSelect(this.state.handle);
            }
        }
    }

    renderStep2() {}

    onVerfuegbar() {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    }

    async getHandles() {
        var handles: any = await domainGetHandles();
        if (handles !== null) {
            this.setState(
                {
                    handles: [...this.state.handles, ...handles],
                    loading: false,
                    handle: "new",
                },
                () => {
                    if (this.props.context.isReseller !== "1") {
                        this.setDefaultPoolIfNotReseller();
                    }
                }
            );
        }
    }

    onDomainChange(domain: string) {
        this.setState({ domain: domain });
    }

    async setDefaultPoolIfNotReseller() {
        if (this.state.pool === null) {
            var pools = await proxGetPools();
            this.setState({ pool: pools[0] });
        }
    }

    selectPool(pool: any) {
        this.setState({ pool: pool });
    }
}

export default withContext(RegisterDomain);
